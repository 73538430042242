import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as ReopenIcon } from "../../../../assets/icons/reopen.svg";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";

import { Pagination } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { fetchSystemUsersList } from "../../../../Redux/Actions/systemUsers/systemUsersList";
import { getStatistics } from "../../../../Services/api/Financial/FinancialProvider";
import { exportRoles } from "../../../../Services/api/Roles/RolesProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import EditRole from "../../Roles/Edit";
import classes from "./UserWalletList.module.css";
import SystemUsersListFilter from "./UserWalletListFilter";
import SystemUsersListTable from "./UserWalletListTable";
import { filterActions } from "./UserWalletListTableActions";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";

function UserWallet(props) {
  require("./UserWallet.css");
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const { systemUsersListMeta } = useSelector(
    (state) => state.systemUsersListReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [rolesModal, setRolesModal] = useState(false);
  const { systemUsersList, systemRolesList } = useSelector(
    (state) => state.systemUsersListReducer
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);

  const fetchStatistics = () => {
    setIsUpdating(true);

    return new Promise((resolve, reject) => {
      getStatistics()
        .then((res) => {
          if (res.status && res.status === 200 && res.data.status) {
            setDashboard(res.data.statistics);

            setIsLoading(false);

            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        });
    });
  };

  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    phone: "",
    id_number: "",
    jobs: "",
    gender: "",
    registerDate: "",
    status: "",
    registerType: "",
    major: "",
    country: "",
    nationality: "",
    iban: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const statistics = [
    {
      label: t("wallet.label.currentamounts"),
      value: dashboard.total,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="check-circle_4_"
            data-name="check-circle (4)"
            d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.depositamounts"),
      value: dashboard.credit,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.withdrawalamounts"),
      value: dashboard.payment,
      color: "#046C77cc",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
          <path
            id="user-pen"
            d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("wallet.label.recoveryamounts"),
      value: dashboard.refund,
      color: "#046C77bb",
      icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
  ];

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      email: "",
      phone: "",
      id_number: "",
      jobs: "",
      gender: "",
      registerDate: "",
      status: "",
      registerType: "",
      major: "",
      country: "",
      nationality: "",
      iban: "",
    });
    setSortColumn("");
    setSortOrder("");
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.email && (filter.email = filterData.email);
    filterData.alternative_email &&
      (filter.alternative_email = filterData.alternative_email);
    filterData.phone && (filter.phone = filterData.phone);
    filterData.id_number && (filter.id_number = filterData.id_number);
    filterData.scghs && (filter.scghs = filterData.scghs);
    filterData.is_medical && (filter.is_medical = filterData.is_medical);
    filterData.jobs && (filter.role = filterData.jobs);
    filterData.gender && (filter.gender = filterData.gender);
    filterData.registerDate &&
      (filter.register_date = moment(filterData.registerDate).format(
        "YYYY-MM-DD"
      ));
    filterData.status && (filter.status = filterData.status);
    filterData.registerType && (filter.type_login = filterData.registerType);
    filterData.major && (filter.specialty_id = filterData.major);
    filterData.department && (filter.department_id = filterData.department);
    filterData.country && (filter.country = filterData.country);
    filterData.nationality && (filter.nationality = filterData.nationality);
    sortColumn && (filter.sort_column = sortColumn);
    sortOrder && (filter.sort_order = sortOrder);
    filterData.switchedFromSSO &&
      (filter.switched_from_sso = filterData.switchedFromSSO);
    filterData.iban && (filter.iban = filterData.iban);

    if (filter.switched_from_sso === "false") {
      delete filter.switched_from_sso;
    }
    return filter;
  };

  const getSystemUsersListHandler = () => {
    const filter = getFilterData();
    setDataToExport(filter);

    dispatch(
      fetchSystemUsersList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getSystemUsersListHandler();
    fetchStatistics();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="home" />,
      pagePath: "/",
    },
    {
      id: "payments",
      page: t("sidebar.label.financial_movement"),
      pagePath: `/${
        localStorage.getItem("type") === "accountant" ? "accountant" : "admin"
      }/financial-movement`,
    },
    {
      id: "wallet",
      page: t("wallet.label.wallet"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      [name === "major" ? "department" : ""]: "",
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportRoles(clearEmptyProp(dataToExport), type, false, true);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const rolesEditHandle = (id) => {
    setRolesModal(!rolesModal);
    setUserInfo(
      systemUsersList.filter((user) => {
        return user.id == id;
      })
    );
  };

  return (
    <>
      {isLoading == false ? (
        <div className={classes["system-users-list"]}>
          {rolesModal && (
            <>
              <div
                className="backdrop"
                style={{
                  position: "fixed",
                }}
                onClick={() => {
                  setRolesModal(false);
                }}
              />
              <EditRole
                roles={systemRolesList}
                users={userInfo}
                onClick={rolesEditHandle}
                fetchRoles={() => getSystemUsersListHandler()}
                setIsUpdating={setIsUpdating}
              />
            </>
          )}
          {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
          <div className="container-fluid">
            <div className={classes["system-users-list__container"]}>
              <div className="row" id="content-header-bar">
                <div className="col-12">
                  <Breadcrumb list={breadcrumbList} />
                </div>
              </div>
              <div
                style={{
                  width: "98%",
                  margin: "auto",
                }}
                className="tw-bg-white tw-rounded tw-shadow tw-p-8 tw-space-y-4 container-fluid"
              >
                <div className="tw-text-teal-700 tw-text-2xl">
                  {t("general.statistics")}
                </div>
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
                  {statistics.map((item) => (
                    <div
                      className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                      style={{ backgroundColor: item.color }}
                    >
                      <div className="tw-h-10 tw-w-10">{item.icon}</div>
                      <div>
                        <div className="tw-text-2xl">{item.value}</div>
                        <div className="tw-text-sm">{item.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MainBox>
                    <MainBoxHead
                      title={
                        i18n.language === Lang.AR
                          ? "محافظ المستخدمين"
                          : "Users Wallets"
                      }
                      actions={filterActions({
                        showFilter: showFilterHandler,
                        addAction: () => {
                          history.push("/admin/create-account");
                        },
                        exportPdf: () => {
                          exportHandler("pdf");
                        },
                        exportExcel: () => {
                          exportHandler("xlsx");
                        },
                      })}
                    />
                    {showFilter && (
                      <SystemUsersListFilter
                        filterData={filterData}
                        changeHandler={changeHandler}
                        resetHandler={resetHandler}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filterHandler={getSystemUsersListHandler}
                        resetFilterHandler={resetFilterHandler}
                      />
                    )}

                    <SystemUsersListTable
                      sortAction={sortActionHandler}
                      rolesEditHandle={rolesEditHandle}
                    />
                    <Pagination
                      count={systemUsersListMeta?.totalPages || 0}
                      showFirstButton
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      className="main-pagination"
                      page={currentPage}
                      onChange={(e, page) => {
                        setCurrentPage(page);
                      }}
                    />
                  </MainBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div
            className="mt-5"
            style={{
              height: "45vh",
            }}
          >
            <SkeletonCard />
          </div>
        </div>
      )}
    </>
  );
}

export default UserWallet;
