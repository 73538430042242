import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FileIcon from "../../../../assets/icons/file.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../../Shared/Components/Input/Input";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import classes from "./createExhibition.module.css";

import { useHistory } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import { toServerTime } from "../../../../Services/Timezone";
import {
  DOC_PDF,
  MP3_TYPE,
  PNG_TYPE,
  acceptAudios,
  acceptDocuments,
  acceptImages,
  fromKbToMb,
  validateArrayOfDocument,
  validateArrayOfImage,
  validateAudio,
  validateDocument,
  validateImage,
} from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import Links from "../../../Shared/Components/Links/Links";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../../Shared/Components/Select/Select";
import CreateExhibitionAction from "./CreateExhibitionAction";

const numberOfBoothsOptions = [
  {
    id: "8",
    value: "8",
    label: "8",
  },
  {
    id: "12",
    value: "12",
    label: "12",
  },
  {
    id: "16",
    value: "16",
    label: "16",
  },
  {
    id: "18",
    value: "18",
    label: "18",
  },
  {
    id: "24",
    value: "24",
    label: "24",
  },
];
const CreateExhibition = ({ submitHandler, type, exhibitionEditData, lessonId }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [editor, setEditor] = useState(exhibitionEditData?.description || null);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);

  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    currentDate();
    setIsLoading(false);
  }, []);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("Exhibition.Exhibition"),
      pagePath: "/Admin/exhibitions-list",
    },
    {
      id: "create_exhibition",
      page: type === "create" ? t("Exhibition.add_exhibition") : exhibitionEditData?.name,
      active: true,
    },
  ];

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours == "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };
  const handleText = (event, editor) => {
    const data = editor.getData();
    setEditor(data);
  };

  const [loading, setLoading] = useState(true);
  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          setLoading(false);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const getMainImage = () => {
    if (!exhibitionEditData?.main_image) return null;
    return {
      preview: exhibitionEditData?.main_image,
    };
  };

  const getLogo = () => {
    if (!exhibitionEditData?.logo) return null;
    return {
      preview: exhibitionEditData?.logo,
    };
  };

  const getAudio = () => {
    if (!exhibitionEditData?.audio) return null;
    return {
      preview: exhibitionEditData?.audio,
    };
  };

  const getBackgroundAudio = () => {
    if (!exhibitionEditData?.background_audio) return null;
    return {
      preview: exhibitionEditData?.background_audio,
    };
  };

  const getDocuments = () => {
    if (!exhibitionEditData?.documents?.length) return [];
    return exhibitionEditData?.documents.map((doc) => ({
      preview: doc?.file,
      status: doc?.status === "available" ? true : false,
      id: doc?.id,
      title: doc?.title,
    }));
  };

  const getGallery = () => {
    if (!exhibitionEditData?.photos?.length) return [];
    return exhibitionEditData?.photos.map((gal) => ({
      preview: gal?.file,
    }));
  };

  const getWebinars = () => {
    if (!exhibitionEditData?.webinars?.length) return [];
    return exhibitionEditData?.webinars.map((web) => ({
      name: web?.name,
      link: web?.link,
    }));
  };

  const validateFile = (file, type) => {
    if (!file) return false;
    if (type === "image" || type === "gallery") {
      return validateImage(file, getTypeFile(PNG_TYPE)[0]?.max_size);
    } else if (type === "audio") {
      return validateAudio(file, getTypeFile(MP3_TYPE)[0]?.max_size);
    } else if (type === "document") {
      return validateDocument(file, getTypeFile(DOC_PDF)[0]?.max_size);
    }
  };

  const validateArrayOfFile = (files, type) => {
    if (!files?.length) return false;
    if (type === "gallery") {
      return validateArrayOfImage(files, getTypeFile(PNG_TYPE)[0]?.max_size);
    } else if (type === "document") {
      return validateArrayOfDocument(files, getTypeFile(DOC_PDF)[0]?.max_size);
    }
  };

  return (
    <div className={classes["create_exhibition"]}>
      <div className="container-fluid">
        <div className={classes["create_exhibition__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      name: exhibitionEditData?.name || "",
                      main_image: exhibitionEditData?.main_image ? getMainImage() : null,
                      logo: exhibitionEditData?.logo ? getLogo() : null,
                      audio: exhibitionEditData?.audio ? getAudio() : null,
                      background_audio: exhibitionEditData?.background_audio ? getBackgroundAudio() : null,
                      description: exhibitionEditData?.description || "",
                      add_files: exhibitionEditData?.documents ? getDocuments() : [],
                      gallery: exhibitionEditData?.photos ? getGallery() : [],
                      links: exhibitionEditData?.webinars ? getWebinars() : [],
                      start_time: exhibitionEditData?.start_time
                        ? new Date(getLocaleTime(new Date(exhibitionEditData?.start_time)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                      end_time: exhibitionEditData?.end_time
                        ? new Date(getLocaleTime(new Date(exhibitionEditData?.end_time)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                      visitors_no: exhibitionEditData?.visitors_no || "",
                      booths_no: exhibitionEditData?.booths_no || "",
                      booth_small_price: exhibitionEditData?.small_price || null,
                      booth_medium_price: exhibitionEditData?.medium_price || null,
                      booth_large_price: exhibitionEditData?.large_price || null,
                      entrance_cost: exhibitionEditData?.entrance_cost || null,
                      status: +exhibitionEditData?.status || 1,
                      mode: exhibitionEditData?.mode || "Paid",
                      booth_mode: exhibitionEditData?.booth_mode || "Paid",
                      filesError: {},
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      let dataToBeUploaded = {
                        ...values,
                        lesson_id: lessonId,
                      };

                      if (values.mode === "Free") {
                        delete dataToBeUploaded.entrance_cost;
                      }
                      if (values.booth_mode === "Free") {
                        dataToBeUploaded.booth_small_price = "0";
                        dataToBeUploaded.booth_medium_price = "0";
                        dataToBeUploaded.booth_large_price = "0";
                      }

                      dataToBeUploaded.start_time = toServerTime(
                        formatDate(new Date(dataToBeUploaded.start_time)) +
                          " " +
                          formatTime(new Date(dataToBeUploaded.start_time))
                      );
                      dataToBeUploaded.end_time = toServerTime(
                        formatDate(new Date(dataToBeUploaded.end_time)) +
                          " " +
                          formatTime(new Date(dataToBeUploaded.end_time))
                      );

                      dataToBeUploaded.main_image = values.main_image?.preview
                        ? values.main_image?.preview.split("exhibitions/")[1]
                        : values.main_image;

                      dataToBeUploaded.logo = values.logo?.preview
                        ? values.logo?.preview.split("exhibitions/")[1]
                        : values.logo;

                      dataToBeUploaded.audio = values.audio?.preview
                        ? values.audio?.preview.split("exhibitions/")[1]
                        : values.audio;

                      dataToBeUploaded.background_audio = values.background_audio?.preview
                        ? values.background_audio?.preview.split("exhibitions/")[1]
                        : values.background_audio;

                      dataToBeUploaded.documents = values.add_files.map((file) => ({
                        file: file?.preview ? file?.preview.split("exhibitions/")[1] : file?.file,
                        title: file?.title,
                        status: file?.status,
                      }));

                      dataToBeUploaded.photos = values.gallery.map((file) => ({
                        file: file?.preview ? file?.preview.split("exhibitions/")[1] : file?.file,
                      }));

                      // dataToBeUploaded.photos = [];

                      // values.gallery.forEach((file) => {
                      //   file?.preview === undefined && dataToBeUploaded.photos.push(file);
                      // });

                      dataToBeUploaded.webinars = values.links;
                      delete dataToBeUploaded.add_files;
                      delete dataToBeUploaded.gallery;
                      delete dataToBeUploaded.links;
                      delete dataToBeUploaded.filesError;

                      await submitHandler(dataToBeUploaded);
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};
                      if (editor) {
                        values.description = editor;
                      }

                      if (!values.main_image) {
                        errors.main_image = t("crud.errors.required");
                      }

                      if (values.filesError["main_image"]?.error) {
                        errors.main_image =
                          t("booth.Note_image_wronge_type") +
                          " PNG " +
                          t("booth.Note_image_large_size") +
                          fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size) +
                          t("booth.megabyte");
                      }

                      if (!values.logo) {
                        errors.logo = t("crud.errors.required");
                      }

                      if (values.filesError["logo"]?.error) {
                        errors.logo =
                          t("booth.Note_image_wronge_type") +
                          " PNG " +
                          t("booth.Note_image_large_size") +
                          fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size) +
                          t("booth.megabyte");
                      }

                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (values.name.length > 46) {
                        errors.name = t("Exhibition.lengthText") + " " + 46;
                      }
                      if (!values.description) {
                        errors.description = t("crud.errors.required");
                      }
                      if (!values.audio) {
                        errors.audio = t("crud.errors.required");
                      }

                      if (values.filesError["audio"]?.error) {
                        errors.audio = `${t("Exhibition.validation_MP3")}, ${t(
                          "Exhibition.validation_MP3_size"
                        )} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t("booth.megabyte")}`;
                      }

                      if (!values.background_audio) {
                        errors.background_audio = t("crud.errors.required");
                      }

                      if (values.filesError["background_audio"]?.error) {
                        errors.background_audio = `${t("Exhibition.validation_MP3")}, ${t(
                          "Exhibition.validation_MP3_size"
                        )} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t("booth.megabyte")}`;
                      }

                      if (!values.end_time) {
                        errors.end_time = t("crud.errors.required");
                      }

                      if (!values.start_time) {
                        errors.start_time = t("crud.errors.required");
                      }

                      if (values.booth_small_price < 0) {
                        errors.booth_small_price = t("crud.errors.invalid_price");
                      }

                      if (values.booth_medium_price < 0) {
                        errors.booth_medium_price = t("crud.errors.invalid_price");
                      }

                      if (values.booth_large_price < 0) {
                        errors.booth_large_price = t("crud.errors.invalid_price");
                      }

                      if (!values.visitors_no) {
                        errors.visitors_no = t("crud.errors.required");
                      }
                      
                      if (Number(values.visitors_no) < 0) {
                        errors.visitors_no = t("crud.errors.positive_number");
                      }

                      if (!values.booths_no) {
                        errors.booths_no = t("crud.errors.required");
                      }

                      if (type === "edit" && values.booths_no < exhibitionEditData.booths_no) {
                        errors.booths_no = t("crud.errors.edit_booth_number");
                      }
                      if (values.mode === "Paid" && values.entrance_cost < 0.01) {
                        errors.entrance_cost = t("crud.errors.invalid_price");
                      }
                      if (values.booth_mode === "Paid" && values.booth_small_price < 0.01) {
                        errors.booth_small_price = t("crud.errors.invalid_price");
                      }

                      if (values.filesError["gallery"]?.error) {
                        errors.arrayOfPhotos =
                          t("booth.Note_image_wronge_type") +
                          " PNG " +
                          t("booth.Note_image_large_size") +
                          fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size) +
                          t("booth.megabyte");
                      }

                      if (values.filesError["add_files"]?.error) {
                        errors.document = `${t("booth.Note_file_wronge_type")} PDF ${t(
                          "booth.Note_file_large_size"
                        )} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t("booth.megabyte")}`;
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form onSubmit={handleSubmit} className={classes["create_exhibition__form"]}>
                        <MainBoxHead
                          title={type === "create" ? t("Exhibition.add_exhibition") : t("Exhibition.edit_exhibition")}
                          actions={[
                            {
                              id: "exhibition-status",
                              type: "switch",
                              value: true,
                              active: t("Exhibition.active"),
                              inactive: t("Exhibition.inactive"),
                              checked: values.status === 1 ? true : false,
                              disabled: type === "edit",
                              onChange: (event) => {
                                setFieldValue("status", event.target.checked ? 1 : 0);
                              },
                            },
                          ]}
                        />

                        <div className={`row ${classes["create_exhibition__form__fields"]}`}>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <Input
                              label={t("Exhibition.name")}
                              type="text"
                              name="name"
                              placeholder={t("Exhibition.exhibition_name")}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <InputFile
                              id="main_image"
                              name="main_image"
                              type="image"
                              placeholder={t("Exhibition.add_main_image")}
                              btnText={t("Exhibition.upload_image")}
                              multiple={false}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptImages()}
                              label={t("Exhibition.add_main_image")}
                              error={errors.main_image}
                              validate={validateFile}
                              value={values.main_image}
                              uploadDirectly={true}
                              note={`${t("Exhibition.png_note")} ${fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <InputFile
                              id="logo"
                              name="logo"
                              type="image"
                              placeholder={t("Exhibition.logo")}
                              btnText={t("Exhibition.upload_image")}
                              multiple={false}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptImages()}
                              label={t("Exhibition.add_logo")}
                              error={errors.logo}
                              validate={validateFile}
                              value={values.logo}
                              uploadDirectly={true}
                              note={`${t("Exhibition.png_note")} ${fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-6`}>
                            <InputFile
                              id="audio"
                              name="audio"
                              type="audio"
                              placeholder={t("Exhibition.drag_audio")}
                              multiple={false}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptAudios()}
                              label={t("Exhibition.add_main_audio")}
                              note={`${t("Exhibition.mp3_note")} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                              error={errors.audio}
                              validate={validateFile}
                              value={values.audio}
                              uploadDirectly={true}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-6`}>
                            <InputFile
                              id="background_audio"
                              name="background_audio"
                              type="audio"
                              placeholder={t("Exhibition.drag_audio")}
                              multiple={false}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptAudios()}
                              label={t("Exhibition.add_second_audio")}
                              note={`${t("Exhibition.mp3_note")} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                              error={errors.background_audio}
                              validate={validateFile}
                              value={values.background_audio}
                              uploadDirectly={true}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <label htmlFor="description">{t("Exhibition.Description")}</label>
                            {/* <CKEditor
                              editor={ClassicEditor}
                              onReady={(editor) => {}}
                              onChange={(event, editor) => {
                                handleText(event, editor);
                              }}
                              onBlur={(event, editor) => {}}
                              onFocus={(event, editor) => {}}
                              data={editor}
                            /> */}
                            <SunEditor
                              placeholder={t("Exhibition.Description")}
                              value={values.description}
                              defaultValue={values.description}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "description",
                                    value: e,
                                  },
                                });
                              }}
                              setOptions={{
                                height: 150,
                                rtl: i18n.language === "ar" ? true : false,
                                buttonList: [
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "lineHeight",
                                    "codeView",
                                  ],
                                ],
                              }}
                              setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                            />
                            {errors.description && (
                              <p className={`form-input-error-space ${classes["create_exhibition__form-error"]}`}>
                                {errors.description}
                              </p>
                            )}
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t("Exhibition.start_date_time")}
                              name="start_time"
                              error={errors.start_time}
                              value={values.start_time}
                              onChange={(date) => {
                                setFieldValue("start_time", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              readOnly={type === "edit"}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t("Exhibition.end_date_time")}
                              name="end_time"
                              error={errors.end_time}
                              value={values.end_time}
                              onChange={(date) => {
                                setFieldValue("end_time", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepickerEnd(0);
                                  setMinutesDatepickerEnd(0);
                                } else {
                                  currentDateEnd();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            {/* number of visiter */}
                            <Input
                              label={t("Exhibition.number_of_visitors")}
                              type="number"
                              name="visitors_no"
                              placeholder={t("Exhibition.number_of_visitors")}
                              onChange={handleChange}
                              error={errors.visitors_no}
                              value={values.visitors_no}
                            />
                          </div>

                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <Select
                              label={t("Exhibition.number_of_booths")}
                              name="booths_no"
                              onchange={handleChange}
                              options={numberOfBoothsOptions}
                              initialValue={t("Exhibition.number_of_booths")}
                              error={errors.booths_no}
                              value={values.booths_no}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            {type === "create" ? (
                              <Checkbox
                                name="boothMode"
                                label={t("Exhibition.booth_mode")}
                                onChange={(event) => {
                                  setFieldValue("booth_mode", event.target.checked ? "Free" : "Paid");
                                }}
                                checked={values.booth_mode === "Free" ? true : false}
                              />
                            ) : (
                              <div className={classes["create_exhibition__form__field-mode"]}>
                                {values.booth_mode === "Free"
                                  ? `* ${t("Exhibition.booth_mode_free")}`
                                  : `* ${t("Exhibition.booth_mode_paid")}`}
                              </div>
                            )}
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-4`}>
                            <Input
                              label={t("Exhibition.boothSmPrice")}
                              type="number"
                              name="booth_small_price"
                              placeholder={t("Exhibition.boothSmPrice")}
                              onChange={handleChange}
                              error={errors.booth_small_price}
                              min={0.01}
                              currency={t("Exhibition.sar_currency")}
                              value={values.booth_mode === "Paid" ? values.booth_small_price : 0}
                              disabled={values.booth_mode === "Free"}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-4`}>
                            <Input
                              label={t("Exhibition.boothMdPrice")}
                              type="number"
                              name="booth_medium_price"
                              placeholder={t("Exhibition.boothMdPrice")}
                              onChange={handleChange}
                              error={errors.booth_medium_price}
                              min={0.01}
                              currency={t("Exhibition.sar_currency")}
                              value={values.booth_mode === "Paid" ? values.booth_medium_price : 0}
                              disabled={values.booth_mode === "Free"}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-4`}>
                            <Input
                              label={t("Exhibition.boothLgPrice")}
                              type="number"
                              name="booth_large_price"
                              placeholder={t("Exhibition.boothLgPrice")}
                              onChange={handleChange}
                              error={errors.booth_large_price}
                              min={0.01}
                              currency={t("Exhibition.sar_currency")}
                              value={values.booth_mode === "Paid" ? values.booth_large_price : 0}
                              disabled={values.booth_mode === "Free"}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <InputFile
                              id="add_files"
                              name="add_files"
                              type="document"
                              placeholder={t("Exhibition.drag_file")}
                              multiple={true}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptDocuments()}
                              label={t("Exhibition.documents")}
                              note={`${t("Exhibition.pdf_note")} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                              error={errors?.document}
                              validateMultiple={validateArrayOfFile}
                              value={values.add_files}
                              uploadDirectly={true}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <Links
                              label={t("Exhibition.webinars")}
                              name="links"
                              placeholder={t("Exhibition.webinars")}
                              namePlaceholder={t("Exhibition.webinar_name")}
                              linkPlaceholder={t("Exhibition.webinar_link")}
                              setFieldValue={setFieldValue}
                              value={values.links}
                            />
                          </div>
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            {type === "create" ? (
                              <Checkbox
                                name="mode"
                                label={t("Exhibition.mode_paid_qa")}
                                onChange={(event) => {
                                  setFieldValue("mode", event.target.checked ? "Free" : "Paid");
                                }}
                                checked={values.mode === "Free" ? true : false}
                              />
                            ) : (
                              <div className={classes["create_exhibition__form__field-mode"]}>
                                {values.mode === "Free"
                                  ? `* ${t("Exhibition.mode_free")}`
                                  : `* ${t("Exhibition.mode_paid")}`}
                              </div>
                            )}
                          </div>
                          {values.mode === "Paid" && (
                            <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                              <Input
                                label={t("Exhibition.enter_price")}
                                type="number"
                                name="entrance_cost"
                                placeholder={t("Exhibition.enter_price")}
                                onChange={handleChange}
                                error={errors.entrance_cost}
                                min={0.01}
                                currency={t("Exhibition.sar_currency")}
                                value={values.entrance_cost}
                              />
                            </div>
                          )}
                          <div className={`${classes["create_exhibition__form__field"]} col-12`}>
                            <InputFile
                              id="gallery"
                              name="gallery"
                              type="gallery"
                              placeholder={""}
                              multiple={true}
                              setFieldValue={setFieldValue}
                              fileIcon={FileIcon}
                              accept={acceptImages()}
                              label={t("Exhibition.add_gallery")}
                              maxSize={`${t("Exhibition.upload_max_size")} ${fromKbToMb(
                                getTypeFile(PNG_TYPE)[0]?.max_size
                              )} ${t("Exhibition.megabyte")}`}
                              error={errors?.arrayOfPhotos}
                              validateMultiple={validateArrayOfFile}
                              value={values.gallery}
                              uploadDirectly={true}
                              note={`${t("Exhibition.png_note")} ${fromKbToMb(getTypeFile(PNG_TYPE)[0]?.max_size)} ${t(
                                "booth.megabyte"
                              )}`}
                            />
                          </div>
                          <CreateExhibitionAction submitAction={handleSubmit} isSubmitting={isSubmitting} />
                        </div>
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateExhibition;
