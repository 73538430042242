import { Modal } from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import AddActivityToLessonModal from "../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import { LessonContentProvider } from "../../../../Providers/LessonContentProvider";
import {
  BanAllActivityComment,
  EnableActivity,
  PublishAllActivityComment,
  addActivityComment,
  disableActivity,
  fetchActivityCommentsList,
  fetchChapterCommentsList,
} from "../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../Services/Timezone";
import { exportLessonDiscussionComments } from "../../../../Services/api/DiscussionBoard/DiscussionProvider";
import { deleteLessonActivity } from "../../../../Services/api/courses/courseProvider";
import { voteAnswer } from "../../../../Services/api/votes/VotesProvider";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar-icon.svg";
import DeleteIconModal from "../../../../assets/icons/delete.svg";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { Lang } from "../../../../utils";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import AddNewItem from "../../../Shared/Components/AddNewItem/AddNewItem";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { CircularPercentBar } from "../../../TrainingCourses/TrainingCourseDetails/TrainingCourseContent/CircularProgressBar";
import CommentForm from "../NewLesson/Content/Activities/List/CommentForm";
import { DiscActions } from "./DiscBoardActions";
import DiscBoardCommentBox from "./DiscBoardCommentBox";
import classes from "./discBoard.module.css";

const DiscBoard = ({ role, type }) => {
  const editDiscModalRef = useRef();
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [addNewComment, setAddNewComment] = useState(false);
  const [ListView, setListView] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openEditDiscModal, setOpenEditDiscModal] = useState(false);
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCourseClosed, setIsCourseClosed] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [vote, setVote] = useState(null);
  const { pusher } = usePusherContext();

  const sortList = [
    {
      id: 1,
      value: {
        key: "created_at",
        dir: "DESC",
      },
      label: t("general.newest"),
      default: true,
    },
    {
      id: 2,
      value: {
        key: "created_at",
        dir: "ASC",
      },
      label: t("general.oldest"),
    },
    {
      id: 3,
      value: {
        key: "ratings_avg_stars",
        dir: "DESC",
      },
      label: t("general.rating_high_to_low"),
    },
  ];
  const [sortable, setSortable] = useState(sortList.find((i) => i.default));

  const { activity, addActivityCommentLoading, activityCommentsListLoading, activityCommentsList } = useSelector(
    (state) => state.activityCommentsListReducer
  );

  const handleCloseModal = () => setIsModalActivityOpen(false);

  const handleOpenModal = () => {
    setIsModalActivityOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteActivity = () => {
    const myPromise = new Promise((resolve, reject) => {
      deleteLessonActivity(activity.id)
        .then(async (result) => {
          if (result.status) {
            toast.success(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.course.lesson.successful_deleting_activity")}
              </span>
            );
            resolve();
          }
          handleCloseDeleteModal();
          getCommentsListHandler();
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("trainer.course.lesson.failed_deleting_activity")}
            </span>
          );

          reject();
        });
    });

    return myPromise;
  };

  const addCommentToLesson = (payload) => {
    dispatch(addActivityComment(payload, getCommentsListHandler));
  };

  const handlePublishAll = () => {
    dispatch(PublishAllActivityComment(activity.id, true));
  };
  const handleBanAll = () => {
    dispatch(BanAllActivityComment(activity.id, true));
  };

  const getCommentsListHandler = () => {
    type === "lesson"
      ? dispatch(
          fetchActivityCommentsList({
            id: id,
            courseId: courseId,
            order: sortable ? sortable.value : sortList.find((i) => i.default)?.value,
            // page: currentPage,
            // perPage: 10,
            // filter,
          })
        )
      : dispatch(
          fetchChapterCommentsList({
            id: id,
            courseId: courseId,
            order: sortable ? sortable.value : sortList.find((i) => i.default)?.value,
            // page: currentPage,
            // perPage: 10,
            // filter,
          })
        );
  };

  useEffect(() => {
    getCommentsListHandler();
    setIsLoading(false);
  }, [sortable]);

  useEffect(() => {
    if (activity) {
      setVote(activity.vote);
      setIsCourseClosed(
        activity?.morph_data?.course_status === "Closed" || activity?.morph_data?.course_status === "مغلقة"
      );
      // setIsDisabled(activity.is_disabled);
    } else {
      setIsCourseClosed(false);
    }
  }, [activity]);

  const openEditDiscModalHandler = () => {
    setOpenEditDiscModal(true);
  };

  const closeEditDiscModalHandler = () => {
    setOpenEditDiscModal(false);
  };

  const downloadActivityFile = (url, name) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const imgextensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "tiff", "webp"];

  const exportHandler = async (type) => {
    setExportLoading(true);
    try {
      await exportLessonDiscussionComments(activity.id, type);
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const disableActionHandler = () => {
    dispatch(disableActivity(activity.id, true));
    // getCommentsListHandler();
  };
  const enableActionHandler = () => {
    dispatch(EnableActivity(activity.id, true));
    // getCommentsListHandler();
  };
  // const [isDisabled, setIsDisabled] = useState(activity.is_disabled);

  useEffect(() => {
    if (activity?.vote?.id) {
      const channel = pusher.subscribe(`vote-channel-${activity?.vote?.id}`);
      channel.bind("App\\Events\\VoteEvent", function (data) {
        setVote(data.vote);
      });

      return () => {
        pusher.unsubscribe(`vote-channel-${vote?.id}`);
      };
    }
  }, [activity?.vote?.id]);

  return (
    <LessonContentProvider>
      <>
        <div className={`${classes["activities-list"]}`}>
          {isLoading || exportLoading ? <SkeletonCardOverlay /> : null}
          {activityCommentsListLoading || addActivityCommentLoading ? (
            <SkeletonCardOverlay />
          ) : (
            <div className="container-fluid">
              <div className={classes["activities-list__container"]}>
                {activity === undefined ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <MainBox>
                          <div className="tw-flex tw-items-center tw-mb-4">
                            <button
                              className={classes["activities-list__back-button"]}
                              onClick={() => history.goBack()}
                            >
                              <i
                                className={`fas fa-chevron-${
                                  i18n.language === Lang.AR ? "right" : "left"
                                } tw-text-black tw-mt-2`}
                              ></i>
                              <span>{t("back")}</span>
                            </button>
                          </div>
                          {role !== "partner" && !isCourseClosed ? (
                            <AddNewItem
                              title={t("trainer.course.lesson.add_activity")}
                              action={{
                                onClick: () => handleOpenModal(),
                              }}
                            />
                          ) : null}
                        </MainBox>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <MainBox>
                        <div className="tw-flex tw-items-center tw-mb-4">
                          <button className={classes["activities-list__back-button"]} onClick={() => history.goBack()}>
                            <i
                              className={`fas fa-chevron-${
                                i18n.language === Lang.AR ? "right" : "left"
                              } tw-text-black tw-mt-2`}
                            ></i>
                            <span>{t("back")}</span>
                          </button>
                        </div>
                        <MainBoxHead title={activity?.name} />
                        <MainBoxHead
                          actions={DiscActions({
                            ...((role === "trainer" || role === "manager" || role === "admin") && !isCourseClosed
                              ? {
                                  votes: () =>
                                    history.push(
                                      vote?.id
                                        ? `/${role}/course/${courseId}/discussion-board/${activity.id}/votes/${vote?.id}/edit`
                                        : `/${role}/course/${courseId}/discussion-board/${activity.id}/votes/add`
                                    ),
                                  participants: () =>
                                    history.push(
                                      `/${role}/course/${courseId}/discussion-board/${activity.id}/participants`
                                    ),
                                  banAllAction: () => handleBanAll(),
                                  publishAllAction: () => handlePublishAll(),
                                  editAction: openEditDiscModalHandler,
                                  ...(activity.is_disabled
                                    ? { enableAction: enableActionHandler }
                                    : { disableAction: disableActionHandler }),

                                  ...(role === "trainer" ? {} : { deleteAction: handleOpenDeleteModal }),
                                }
                              : {}),
                            ...(role !== "partner" && role !== "trainee"
                              ? {
                                  exportPdf: () => exportHandler("pdf"),
                                  exportExcel: () => exportHandler("xlsx"),
                                }
                              : {}),
                          })}
                        />
                        {vote?.id &&
                          (role !== "trainee" || activity.time_status === "Finished" || activity.is_disabled ? (
                            <div className="tw-space-y-2">
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  gap: "30px",
                                }}
                              >
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("vote")}</div>
                              </div>
                              <div className="tw-p-4 border tw-space-y-4">
                                <div className="tw-space-y-4 tw-border-b-4 tw-pb-2 tw-flex tw-flex-col tw-items-center">
                                  {vote?.attachment?.url && (
                                    <img src={vote?.attachment?.url} className="tw-h-96 tw-shadow tw-max-w-full" />
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: vote?.question,
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="tw-space-y-4">
                                    <div className="tw-space-y-2 tw-divide-y">
                                      {vote?.answers?.map((answer, index) => (
                                        <div key={index} className="tw-flex tw-items-center tw-justify-between">
                                          <div className="tw-space-y-2 tw-p-2">
                                            <div className={`tw-text-gray-500 tw-pb-2`}>{answer.answer}</div>
                                            {answer?.attachment?.url ? (
                                              <img
                                                src={answer.attachment.url}
                                                className="tw-h-60 tw-shadow tw-max-w-full"
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <CircularPercentBar
                                            percent={answer.answer_percentage}
                                            title={answer.answer_count}
                                            color="#2e3e5f"
                                            style={{
                                              minWidth: "3.5rem",
                                              minHeight: "3.5rem",
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    {true ? (
                                      <></>
                                    ) : (
                                      <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                                        <button
                                          type="button"
                                          className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                                        >
                                          {t("عرض التفاصيل")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="tw-space-y-2 tw-mt-4">
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  gap: "30px",
                                }}
                              >
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("vote")}</div>
                              </div>
                              <div className="tw-p-4 tw-space-y-4 border">
                                <div className="tw-space-y-4 tw-border-b-4 tw-pb-2 tw-flex tw-flex-col tw-items-center">
                                  {activity.vote?.attachment?.url && (
                                    <img
                                      src={activity.vote?.attachment?.url}
                                      className="tw-h-96 tw-shadow tw-max-w-full"
                                    />
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: activity.vote?.question,
                                    }}
                                  />
                                </div>
                                <div>
                                  <Formik
                                    initialValues={{
                                      vote_id: activity.vote?.id,
                                      vote_answer_id: "",
                                    }}
                                    onSubmit={async (values, { setErrors }) => {
                                      try {
                                        if (
                                          !moment(new Date()).isBetween(
                                            getLocaleTime(new Date(activity.vote.start_date)),
                                            getLocaleTime(new Date(activity.vote.end_date))
                                          )
                                        ) {
                                          return;
                                        }
                                        const res = await voteAnswer(values);

                                        if (res.data.status) {
                                          toast.success(
                                            <span
                                              style={{
                                                fontSize: 13,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {t("شكرا لاتمامك التصويت")}
                                            </span>
                                          );
                                          getCommentsListHandler();
                                        } else {
                                          throw res;
                                        }
                                      } catch (error) {
                                        toast.error(
                                          <span
                                            style={{
                                              fontSize: 13,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {t("لم نتمكن من تسجيل اجاباتك")}
                                          </span>
                                        );
                                      }
                                    }}
                                    validate={(values) => {
                                      const errors = {};

                                      if (!values.vote_answer_id) {
                                        values.vote_answer_id = t("crud.errors.required");
                                      }
                                      return errors;
                                    }}
                                    // validateOnChange={hasSubmitted}
                                  >
                                    {({
                                      setFieldValue,
                                      setValues,
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      handlevalidate,
                                      isSubmitting,
                                      resetForm,
                                      submitForm,
                                      validateForm,
                                    }) => (
                                      <form onSubmit={handleSubmit}>
                                        <div className="tw-space-y-4">
                                          <div className="tw-space-y-2 tw-divide-y">
                                            {activity.vote?.answers?.map((answer, index) => (
                                              <div
                                                key={index}
                                                className="tw-flex tw-items-center tw-justify-between tw-p-2"
                                              >
                                                <div className="tw-space-y-2">
                                                  <label className="tw-flex tw-items-center tw-space-s-2 tw-m-0">
                                                    {!activity.vote_answered &&
                                                      moment(new Date()).isBetween(
                                                        getLocaleTime(new Date(activity.vote.start_date)),
                                                        getLocaleTime(new Date(activity.vote.end_date))
                                                      ) && (
                                                        <Field
                                                          type={"radio"}
                                                          id={`answers.${index}.answer`}
                                                          value={answer.id}
                                                          checked={
                                                            values.vote_answer_id == answer.id && answer.id != null
                                                          }
                                                          onChange={({ target: { value } }) => {
                                                            setFieldValue(`vote_answer_id`, value);
                                                          }}
                                                        />
                                                      )}
                                                    <div className={`tw-text-gray-500 tw-pb-2`}>{answer.answer}</div>
                                                  </label>
                                                  {activity.vote_answered ? (
                                                    <></>
                                                  ) : (
                                                    <ErrorMessage
                                                      name={`vote_answer_id`}
                                                      component="div"
                                                      className="tw-text-xs tw-text-red-700 tw-h-4"
                                                    />
                                                  )}
                                                  {answer?.attachment?.url ? (
                                                    <img
                                                      src={answer.attachment.url}
                                                      className="tw-h-60 tw-shadow tw-max-w-full"
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                                {activity.vote?.view_result == 1 ||
                                                (activity.vote_answered && activity.vote?.view_result == 2) ? (
                                                  <CircularPercentBar
                                                    percent={answer.answer_percentage}
                                                    title={answer.answer_count}
                                                    color="#2e3e5f"
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                          {activity.vote_answered ||
                                          !moment(new Date()).isBetween(
                                            getLocaleTime(new Date(activity.vote.start_date)),
                                            getLocaleTime(new Date(activity.vote.end_date))
                                          ) ? (
                                            <></>
                                          ) : (
                                            <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                                              <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                                              >
                                                {t("survey.buttons.save")}
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </form>
                                    )}
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className="tw-space-y-2">
                          <div
                            className="d-flex align-items-center"
                            style={{
                              gap: "30px",
                            }}
                          >
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                              {t("trainer.course.lesson.activity.activity_details")}
                            </div>
                          </div>
                          <div className="tw-p-4 border">
                            <div className="tw-flex tw-items-center tw-space-s-2 tw-text-teal-500">
                              <CalendarIcon className="tw-h-4" />
                              <div>
                                {`${moment(toLocalTimezone(activity?.created_at)).local(true).format("DD/MM/YYYY")} ${t(
                                  "the_hour"
                                )}
                    ${moment(toLocalTimezone(activity?.created_at)).local(true).format("hh:mm A")}`}
                              </div>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: activity?.desc,
                              }}
                            />
                            {role !== "trainee" && (
                              <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-4 tw-my-4">
                                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-bg-slate-50 tw-space-s-3 tw-me-auto tw-my-auto">
                                  <div className="tw-text-gray-500">{t("عدد المتدربين المشاركين")}</div>
                                  <div className="tw-font-bold tw-text-xl">
                                    {activity?.published_participators_count}
                                  </div>
                                </div>
                                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-bg-slate-50 tw-space-s-3 tw-me-auto tw-my-auto">
                                  <div className="tw-text-gray-500">{t("عدد المشاركات المنشوره")}</div>
                                  <div className="tw-font-bold tw-text-xl">{activity?.published_comments_number}</div>
                                </div>
                                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-bg-slate-50 tw-space-s-3 tw-me-auto tw-my-auto">
                                  <div className="tw-text-gray-500">{t("عدد المشاركات المحظوره")}</div>
                                  <div className="tw-font-bold tw-text-xl">{activity?.banned_comments_number}</div>
                                </div>
                                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-bg-slate-50 tw-space-s-3 tw-me-auto tw-my-auto">
                                  <div className="tw-text-gray-500">{t("عدد الردود المنشوره")}</div>
                                  <div className="tw-font-bold tw-text-xl">{activity?.published_replies_number}</div>
                                </div>
                                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-full tw-bg-slate-50 tw-space-s-3 tw-me-auto tw-my-auto">
                                  <div className="tw-text-gray-500">{t("عدد الردود المحظوره")}</div>
                                  <div className="tw-font-bold tw-text-xl">{activity?.banned_replies_number}</div>
                                </div>
                              </div>
                            )}
                            <div className="tw-py-4">
                              {Object.keys(activity.attachment ?? {}).length > 0 ? (
                                imgextensions.includes(activity.attachment.name.split(".").slice(-1)[0]) ? (
                                  <>
                                    <div
                                      className="tw-flex tw-flex-col tw-items-center   tw-bg-gray-100 tw-rounded tw-p-2"
                                      style={{ width: "25%" }}
                                    >
                                      <div className="tw-flex tw-items-center ">
                                        <div className="tw-p-2.5 tw-bg-gray-200">
                                          <div className="tw-p-2.5  tw-bg-gray-200">
                                            <img
                                              src={activity.attachment.url}
                                              alt=""
                                              className=""
                                              style={{
                                                height: 120,
                                                width: 120,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="tw-flex tw-justify-center">
                                        <div>{activity.attachment.name}</div>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            downloadActivityFile(activity.attachment.url, activity.attachment.name);
                                          }}
                                        >
                                          <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                                    <div className="tw-flex tw-items-center tw-space-s-2">
                                      <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                                        <DocumentIcon className="tw-h-4 tw-w-4" />
                                      </div>

                                      <div>{activity.attachment.name}</div>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          downloadActivityFile(activity.attachment.url, activity.attachment.name);
                                        }}
                                      >
                                        <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                      </button>
                                    </div>
                                  </div>
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {!isCourseClosed &&
                        !activity.is_disabled &&
                        activity.time_status !== "Finished" &&
                        role !== "partner" ? (
                          <AddNewItem
                            title={t("trainer.course.lesson.activity.add_comment")}
                            action={{
                              onClick: () => setAddNewComment(!addNewComment),
                              disabled: activity.is_disabled || activity.time_status === "Finished",
                            }}
                          />
                        ) : null}
                        {addNewComment && !isCourseClosed ? (
                          <CommentForm
                            handleClose={() => setAddNewComment(!addNewComment)}
                            isComment={true}
                            discussionId={activity.id}
                            addCommentHandler={addCommentToLesson}
                          />
                        ) : null}
                        <div id="replies-container">
                          <div className="tw-flex tw-items-center tw-justify-between tw-pe-4 tw-pb-2">
                            <div className="tw-text-teal-600 tw-font-bold tw-mb-2">
                              {t("trainer.course.lesson.activity.coments")}
                              {activity?.interactions_agree && (
                                <span className={classes["comment_approve_msg"]}>
                                  {" "}
                                  {`( ${t("comment_need_aprove_msg")} )`}
                                </span>
                              )}
                            </div>
                            <div className="tw-min-w-[16rem]">
                              <Select
                                id="sort"
                                isClearable
                                options={sortList}
                                value={sortable}
                                getOptionValue={(item) => item.id}
                                onChange={(item) => {
                                  console.log({ item });
                                  if (item) {
                                    setSortable(item);
                                  } else {
                                    setSortable(sortList.find((i) => i.default));
                                  }
                                }}
                                placeholder={t("general.newest")}
                              />
                            </div>
                          </div>
                          <div className="tw-pe-4 tw-space-y-4">
                            {activityCommentsList?.map((item) =>
                              role === "trainer" || role === "manager" || role === "admin" || item.is_published ? (
                                <DiscBoardCommentBox
                                  item={item}
                                  isAdmin={role === "admin"}
                                  isTrainer={role === "trainer"}
                                  isManager={role === "manager"}
                                  role={role}
                                  discussionId={activity.id}
                                  isClosed={isCourseClosed}
                                  isDisabled={activity.is_disabled}
                                  timeStatus={activity.time_status}
                                  type={type}
                                  sortable={sortable}
                                />
                              ) : null
                            )}
                          </div>
                        </div>
                      </MainBox>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* <BasicModal ref={editDiscModalRef}>
            {openEditDiscModal && (
              <DiscFormModal
                activity={activity}
                title={t("trainer.course.lesson.activity.edit")}
                dismissModal={closeEditDiscModalHandler}
                getCommentsListHandler={getCommentsListHandler}
                activityId={activity.id}
              />
            )}
          </BasicModal> */}
          {isModalActivityOpen && (
            <AddActivityToLessonModal
              courseId={courseId}
              chapterId={id}
              id={activity?.id}
              dismissModal={handleCloseModal}
              title={t(`trainer.course.lesson.add_activity`)}
              isChapter={type !== "lesson" ? true : false}
              sortable={sortable}
            />
          )}
          {openEditDiscModal && (
            <Modal open={openEditDiscModal} onClose={() => setOpenEditDiscModal(false)}>
              <AddActivityToLessonModal
                id={activity?.id}
                courseId={courseId}
                chapterId={id}
                activity={activity}
                dismissModal={closeEditDiscModalHandler}
                title={t(`trainer.course.lesson.activity.edit`)}
                isChapter={type !== "lesson" ? true : false}
                sortable={sortable}
              />
            </Modal>
          )}
          {isDeleteModalOpen && (
            <SharedModal
              icon={DeleteIconModal}
              title={t("disc_delete_msg")}
              confirmMsg={t("confirm_disc_delete_msg")}
              confirmText={t("general.confirm")}
              cancelText={t("general.cancel")}
              onCancel={handleCloseDeleteModal}
              onConfirm={() => deleteActivity()}
              isLoading={false}
            />
          )}
        </div>
      </>
    </LessonContentProvider>
  );
};

export default DiscBoard;
