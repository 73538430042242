import { ErrorMessage, Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BanAllActivityComment,
  EnableActivity,
  PublishAllActivityComment,
  addActivityComment,
  deleteActivityComment,
  disableActivity,
  fetchActivityCommentsList,
} from "../../../../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../../../../Services/Timezone";
import { exportLessonDiscussionComments } from "../../../../../../../Services/api/DiscussionBoard/DiscussionProvider";
import { voteAnswer } from "../../../../../../../Services/api/votes/VotesProvider";
import { ReactComponent as CalendarIcon } from "../../../../../../../assets/icons/calendar-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../../assets/icons/download.svg";
import HomeIcon from "../../../../../../../assets/icons/home.svg";
import { Lang } from "../../../../../../../utils";
import { getLocaleTime } from "../../../../../../../utils/utcToLocal";
import AddNewItem from "../../../../../../Shared/Components/AddNewItem/AddNewItem";
import MainBox from "../../../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CircularProgressBar, {
  CircularPercentBar,
} from "../../../../../../TrainingCourses/TrainingCourseDetails/TrainingCourseContent/CircularProgressBar";
import DiscFormModal from "../../../../discBoard/DiscFormModal/DiscFormModal";
import { ActivitiesPreviewActions } from "./ActivitiesPreviewActions";
import ActivitiyCommentBox from "./ActivitiyCommentBox";
import CommentForm from "./CommentForm";
import classes from "./activities.module.css";

function Activity({ role }) {
  require("./Activity.css");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const editDiscModalRef = useRef();

  const [addNewComment, setAddNewComment] = useState(false);
  const [openEditDiscModal, setOpenEditDiscModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCourseClosed, setIsCourseClosed] = useState(true);

  const {
    activityCommentsList,
    activity,
    addActivityCommentLoading,
    activityCommentsListLoading,
  } = useSelector((state) => state.activityCommentsListReducer);

  const addCommentToLesson = (payload) => {
    if (!addActivityCommentLoading) {
      dispatch(addActivityComment(payload, getCommentsListHandler));
      // getCommentsListHandler();
    }
  };

  const handlePublishAll = () => {
    dispatch(PublishAllActivityComment(id));
  };
  const handleBanAll = () => {
    dispatch(BanAllActivityComment(id));
  };

  const getCommentsListHandler = () => {
    dispatch(
      fetchActivityCommentsList({
        id: id,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };

  useEffect(() => {
    getCommentsListHandler();
  }, []);
  useEffect(() => {}, [deleteActivityComment]);
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("trainee.lesson.activity.title"),
      pagePath: "#",
    },
    {
      id: `content_development_tax`,
      page: activityCommentsList?.name,
      pagePath: "#",
      active: true,
    },
  ];

  const downloadActivityFile = (url, name) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportHandler = async (type) => {
    setExportLoading(true);
    try {
      await exportLessonDiscussionComments(id, type);
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const openEditDiscModalHandler = () => {
    setOpenEditDiscModal(true);
    editDiscModalRef.current.showModal();
  };

  const closeEditDiscModalHandler = () => {
    setOpenEditDiscModal(false);
    editDiscModalRef.current.dismissModal();
  };

  useEffect(() => {
    if (Object.keys(activity).length) {
      setIsCourseClosed(
        activity.morph_data.course?.status === "Closed" ||
          activity.morph_data.course?.status === "مغلقة" ||
          activity.morph_data.status === "Closed" ||
          activity.morph_data.status === "مغلقة"
      );
    }
  }, [activity]);
  const disableActionHandler = () => {
    dispatch(disableActivity(id, false));
  };
  const enableActionHandler = () => {
    dispatch(EnableActivity(id, false));
  };
  const imgextensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "webp",
  ];

  return (
    <>
      <div className={classes["activities-list"]}>
        <div className="container-fluid">
          {exportLoading ||
          isLoading ||
          addActivityCommentLoading ||
          activityCommentsListLoading ? (
            <SkeletonCardOverlay skeletonWidth="100" />
          ) : null}
          <div className={classes["activities-list__container"]}>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <div>
                    <div className="tw-flex tw-items-center tw-mb-4">
                      <button
                        className={classes["activities-list__back-button"]}
                        onClick={() => history.goBack()}
                      >
                        <i
                          className={`fas fa-chevron-${
                            i18n.language === Lang.AR ? "right" : "left"
                          } tw-text-black tw-mt-2`}
                        ></i>
                        <span>{t("back")}</span>
                      </button>
                    </div>
                    <MainBoxHead
                      title={activity?.name}
                      actions={ActivitiesPreviewActions({
                        ...(role === "admin" ||
                        role === "manager" ||
                        role === "trainer"
                          ? {
                              publishAllAction: () => handlePublishAll(),
                              banAllAction: () => handleBanAll(),
                              editAction: openEditDiscModalHandler,
                              ...(activity.is_disabled
                                ? { enableAction: enableActionHandler }
                                : { disableAction: disableActionHandler }),
                            }
                          : {}),

                        exportPdf: () => exportHandler("pdf"),
                        exportExcel: () => exportHandler("xlsx"),
                      })}
                    />
                    {activity.vote?.id && (
                      <div className="tw-space-y-2 tw-mt-4">
                        <div
                          className="d-flex align-items-center"
                          style={{
                            gap: "30px",
                          }}
                        >
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("vote")}
                          </div>
                        </div>
                        <div className="tw-p-4 tw-space-y-4 border">
                          <div className="tw-space-y-4 tw-border-b-4 tw-pb-2 tw-flex tw-flex-col tw-items-center">
                            {activity.vote?.attachment?.url && (
                              <img
                                src={activity.vote?.attachment?.url}
                                className="tw-h-48 tw-shadow tw-max-w-full"
                              />
                            )}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: activity.vote?.question,
                              }}
                            />
                          </div>
                          <div>
                            <Formik
                              initialValues={{
                                vote_id: activity.vote?.id,
                                vote_answer_id: "",
                              }}
                              onSubmit={async (values, { setErrors }) => {
                                try {
                                  if (
                                    !moment(new Date()).isBetween(
                                      getLocaleTime(
                                        new Date(activity.vote.start_date)
                                      ),
                                      getLocaleTime(
                                        new Date(activity.vote.end_date)
                                      )
                                    )
                                  ) {
                                    return;
                                  }
                                  const res = await voteAnswer(values);

                                  if (res.data.status) {
                                    toast.success(
                                      <span
                                        style={{
                                          fontSize: 13,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {t("شكرا لاتمامك التصويت")}
                                      </span>
                                    );
                                    getCommentsListHandler();
                                  } else {
                                    throw res;
                                  }
                                } catch (error) {
                                  toast.error(
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {t("لم نتمكن من تسجيل اجاباتك")}
                                    </span>
                                  );
                                }
                              }}
                              validate={(values) => {
                                const errors = {};

                                if (!values.vote_answer_id) {
                                  values.vote_answer_id = t(
                                    "crud.errors.required"
                                  );
                                }
                                return errors;
                              }}
                              // validateOnChange={hasSubmitted}
                            >
                              {({
                                setFieldValue,
                                setValues,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handlevalidate,
                                isSubmitting,
                                resetForm,
                                submitForm,
                                validateForm,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <div className="tw-space-y-4">
                                    <div className="tw-space-y-2 tw-divide-y">
                                      {activity.vote?.answers.map(
                                        (answer, index) => (
                                          <div
                                            key={index}
                                            className="tw-flex tw-items-center tw-justify-between tw-p-2"
                                          >
                                            <div className="tw-space-y-2">
                                              <label className="tw-flex tw-items-center tw-space-s-2 tw-m-0">
                                                {!activity.vote_answered &&
                                                  moment(new Date()).isBetween(
                                                    getLocaleTime(
                                                      new Date(
                                                        activity.vote.start_date
                                                      )
                                                    ),
                                                    getLocaleTime(
                                                      new Date(
                                                        activity.vote.end_date
                                                      )
                                                    )
                                                  ) && (
                                                    <Field
                                                      type={"radio"}
                                                      id={`answers.${index}.answer`}
                                                      value={answer.id}
                                                      checked={
                                                        values.vote_answer_id ==
                                                          answer.id &&
                                                        answer.id != null
                                                      }
                                                      onChange={({
                                                        target: { value },
                                                      }) => {
                                                        setFieldValue(
                                                          `vote_answer_id`,
                                                          value
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                <div
                                                  className={`tw-text-gray-500 tw-pb-2`}
                                                >
                                                  {answer.answer}
                                                </div>
                                              </label>
                                              {activity.vote_answered ? (
                                                <></>
                                              ) : (
                                                <ErrorMessage
                                                  name={`vote_answer_id`}
                                                  component="div"
                                                  className="tw-text-xs tw-text-red-700 tw-h-4"
                                                />
                                              )}
                                              {activity.vote_answered &&
                                              answer?.attachment?.url ? (
                                                <img
                                                  src={answer.attachment.url}
                                                  className="tw-h-20 tw-shadow tw-max-w-full"
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            {activity.vote?.view_result == 1 ||
                                            (activity.vote_answered &&
                                              activity.vote?.view_result ==
                                                2) ? (
                                              <CircularPercentBar
                                                percent={
                                                  answer.answer_percentage
                                                }
                                                title={answer.answer_count}
                                                color="#2e3e5f"
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    {activity.vote_answered ||
                                    !moment(new Date()).isBetween(
                                      getLocaleTime(
                                        new Date(activity.vote.start_date)
                                      ),
                                      getLocaleTime(
                                        new Date(activity.vote.end_date)
                                      )
                                    ) ? (
                                      <></>
                                    ) : (
                                      <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                                        <button
                                          type="submit"
                                          disabled={isSubmitting}
                                          className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                                        >
                                          {t("survey.buttons.save")}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="tw-space-y-2">
                    <div
                      className="d-flex align-items-center"
                      style={{
                        gap: "30px",
                      }}
                    >
                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("trainer.course.lesson.activity.activity_details")}
                      </div>
                    </div>
                    <div className="tw-p-4 border">
                      <div className="tw-flex tw-items-center tw-space-s-2 tw-text-teal-500">
                        <CalendarIcon className="tw-h-4" />
                        <div>
                          {`${moment(toLocalTimezone(activity.created_at))
                            .local(true)
                            .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(activity?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
                        </div>
                      </div>
                      <div
                        className="tw-text-gray-400 tw-text-sm tw-p-5"
                        dangerouslySetInnerHTML={{ __html: activity?.desc }}
                      ></div>
                      <div className="tw-py-4">
                        {Object.keys(activity.attachment ?? {}).length ? (
                          imgextensions.includes(
                            activity.attachment.name.split(".").slice(-1)[0]
                          ) ? (
                            <>
                              <div
                                className="tw-flex tw-flex-col tw-items-center   tw-bg-gray-100 tw-rounded tw-p-2"
                                style={{ width: "25%" }}
                              >
                                <div className="tw-flex tw-items-center ">
                                  <div className="tw-p-2.5 tw-bg-gray-200">
                                    <div className="tw-p-2.5  tw-bg-gray-200">
                                      <img
                                        src={activity.attachment.url}
                                        alt=""
                                        className=""
                                        style={{
                                          height: 120,
                                          width: 120,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="tw-flex tw-justify-center">
                                  <div>{activity.attachment.name}</div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      downloadActivityFile(
                                        activity.attachment.url,
                                        activity.attachment.name
                                      );
                                    }}
                                  >
                                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                              <div className="tw-flex tw-items-center tw-space-s-2">
                                <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                                  <DocumentIcon className="tw-h-4 tw-w-4" />
                                </div>

                                <div>{activity.attachment.name}</div>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadActivityFile(
                                      activity.attachment.url,
                                      activity.attachment.name
                                    );
                                  }}
                                >
                                  <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                </button>
                              </div>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div id="replies-container">
                    <div className="tw-text-teal-600 tw-font-bold tw-mb-2">
                      <span>{t("trainer.course.lesson.activity.coments")}</span>
                      {activity?.interactions_agree && (
                        <span className={classes["comment_approve_msg"]}>
                          {" "}
                          {`( ${t("comment_need_aprove_msg")} )`}
                        </span>
                      )}
                    </div>
                    {role !== "partner" &&
                    !isCourseClosed &&
                    !activity.is_disabled &&
                    activity.time_status !== "Finished" ? (
                      <AddNewItem
                        title={t("trainer.course.lesson.activity.add_comment")}
                        action={{
                          onClick: () => setAddNewComment(!addNewComment),
                          disabled:
                            activity.is_disabled ||
                            activity.time_status === "Finished",
                        }}
                      />
                    ) : null}
                    {addNewComment && (
                      <CommentForm
                        handleClose={() => setAddNewComment(!addNewComment)}
                        isComment={true}
                        addCommentHandler={addCommentToLesson}
                      />
                    )}
                    <div
                      className="tw-pe-4 tw-space-y-4"
                      // style={{ maxHeight: "80vh" }}
                    >
                      {activityCommentsList?.map((item) =>
                        role === "admin" ||
                        role === "manager" ||
                        role === "trainer" ? (
                          <ActivitiyCommentBox
                            item={item}
                            isAdmin={role === "admin"}
                            isTrainer={role === "trainer"}
                            isManager={role === "manager"}
                            isPartner={role === "partner"}
                            isCourseClosed={isCourseClosed}
                            role={role}
                          />
                        ) : item.is_published ? (
                          <ActivitiyCommentBox
                            item={item}
                            isAdmin={role === "admin"}
                            isTrainer={role === "trainer"}
                            isManager={role === "manager"}
                            isPartner={role === "partner"}
                            role={role}
                            isCourseClosed={isCourseClosed}
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>

                  <BasicModal ref={editDiscModalRef}>
                    {openEditDiscModal && (
                      <DiscFormModal
                        title={t("trainer.course.lesson.activity.edit")}
                        activityId={id}
                        dismissModal={closeEditDiscModalHandler}
                        chapter={activity}
                        getCommentsListHandler={getCommentsListHandler}
                      />
                    )}
                  </BasicModal>
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Activity;
