import { Formik } from "formik";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { addTrainerDues } from "../../../../Services/api/toutorials/assignToCourseProvider";
import CoinsIcon from "../../../../assets/icons/coins.png";
import AddIcon from "../../../../assets/icons/plus-green.svg";
import exit from "../../../../assets/icons/remove-red.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import Select from "../../../Shared/Components/Select/Select";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./departmentsTrainers.module.css";
import { useHistory } from "react-router";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import toLocalTimezone from "../../../../Services/Timezone";

const DepartmentsTrainersTable = ({
  trainers,
  loading,
  setLoading,
  getTrainersApi,
  programCourses,
  role,
  isProgram,
  backPath,
}) => {
  const addDuesModal = useRef(null);
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const authContext = useContext(AuthContext);
  const [showAddDuesModal, setShowAddDuesModal] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const history = useHistory();

  const departmentsTrainersTableActions = [
    ...(!isProgram || true
      ? [
          {
            id: "view-details",
            icon: <EyeIcon className="tw-w-5 tw-h-5" />,
            tooltip: t("general.view_details"),
            action: (rowData) => {
              history.push(
                `/${role}${
                  (role === "manager" ||
                    role === "trainer" ||
                    role === "partner") &&
                  programCourses
                    ? backPath === "programs-history"
                      ? `/programs-history/program/${id}`
                      : `/programs/program/${id}`
                    : ""
                }${programCourses && role === "admin" ? `/program/${id}` : ``}/${
                  isProgram && (role === "manager" || role === "admin")
                    ? backPath === "programs-history"
                      ? `programs-history/program/${id}`
                      : `program/${id}`
                    : `${
                        backPath === "courses-history" ? "courses-history/" : ""
                      }course/${!programCourses && !isProgram ? id : courseId}`
                }/trainers/${rowData}`
              );
            },
          },
        ]
      : []),
    ...(role !== "partner" && role !== "manager"
      ? [
          {
            id: "add_dues",
            icon: <img src={CoinsIcon} alt="" style={{ width: 30, height: 30 }} />,
            action: (rowData) => {
              setSelectedTrainer(rowData);
              openAddDuesModal();
            },
            tooltip: t("department_trainers.add_dues"),
          },
        ]
      : []),
  ];

  const openAddDuesModal = () => {
    setShowAddDuesModal(true);
    addDuesModal.current.showModal();
  };

  const closeAddDuesModal = () => {
    setShowAddDuesModal(false);
    addDuesModal.current.dismissModal();
  };

  const displayPermission = (rowIndex) => {
    return rowIndex === 0 && !programCourses ? t("department_trainers.manager") : t("department_trainers.trainer");
  };

  const displayMechanism = (mechanism) => {
    return mechanism
      ? mechanism === "1"
        ? t("department_trainers.amount")
        : t("department_trainers.percentage")
      : "-";
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainers__table-photo"]}>
          {rowData.img ? <img src={rowData.img} alt="" /> : <i className="fa fa-user" aria-hidden="true"></i>}
        </div>
      ),
      sortable: false,
    },
    {
      id: "name",
      name: t("department_trainers.trainer_name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a className={classes["departments-trainers__table-email"]} href={`mailto: ${rowData?.email}`}>
          {rowData?.email}
        </a>
      ),
      sortable: false,
    },
    {
      id: "permission",
      name: t("department_trainers.permission"),
      key: "permission",
      cell: ({ rowData, rowIndex }) => <>{displayPermission(rowIndex)}</>,
      sortable: false,
    },
    // {
    //   id: "mechanism",
    //   name: t("department_trainers.mechanism"),
    //   key: "mechanism",
    //   cell: ({ rowData }) => <>{displayMechanism(rowData?.dues?.mechanism)}</>,
    //   sortable: false,
    // },
    ...(role !== "trainer"
      ? [
          {
            id: "value",
            name: t("department_trainers.value"),
            key: "value",
            cell: ({ rowData }) => (
              <>
                {rowData?.dues?.value
                  ? `${rowData?.dues?.value} ${rowData?.dues?.mechanism === "1" ? t("rs") : "%"}`
                  : "-"}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) =>
        rowData?.dues?.created_at ? (
          <>
            {moment(rowData?.dues?.created_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.dues?.created_at)}
          </>
        ) : (
          "-"
        ),
      sortable: false,
    },
    {
      id: "last_seen",
      name: t("general.last_seen"),
      key: "last_seen",
      cell: ({ rowData }) => (
        <>
          {rowData?.last_seen ? moment(rowData?.last_seen).format("DD/MM/YYYY") : "-"}
          <br />
          {rowData?.last_seen ? utcToLocal(rowData?.last_seen) : "-"}
        </>
      ),
    },
    // number of days
    {
      id: "days",
      name: t("general.days_number"),
      key: "days",
      cell: ({ rowData }) => (
        <>{rowData?.last_seen ? `${moment().diff(toLocalTimezone(rowData?.last_seen), "days")}` : "-"}</>
      ),
    },
    {
      id: "rating",
      name: t("general.rating"),
      key: "rating",
      cell: ({ rowData }) => (
        <>
          <StarsRating rating={rowData.rate_by_course || 0} />
          {role === "admin" || role === "manager" ? (
            <p
              className="tw-cursor-pointer"
              style={{ color: "#27b6be" }}
              onClick={() =>
                history.push(
                  `/${role}${
                    (role === "manager" || role === "trainer") && programCourses
                      ? backPath === "programs-history"
                        ? `/programs-history/program/${id}`
                        : `/programs/program/${id}`
                      : ""
                  }${programCourses && role === "admin" ? `/program/${id}` : ``}/${
                    isProgram && (role === "manager" || role === "admin")
                      ? backPath === "programs-history"
                        ? `programs-history/program/${id}`
                        : `program/${id}`
                      : `course/${!programCourses && !isProgram ? id : courseId}`
                  }/trainers-ratting/${rowData.id}`
                )
              }
            >
              {t("show_rating")}
            </p>
          ) : (
            <></>
          )}
        </>
      ),
      sortable: false,
    },
    ...(role !== "partner" && role !== "trainer"
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <div
                className={`${classes["courses-list__table__actions"]} tw-flex tw-justify-center tw-items-center tw-space-s-4`}
              >
                {departmentsTrainersTableActions.map((action) => {
                  return (
                    <button
                      key={action.id}
                      className={classes["courses-list__table__actions-btn"]}
                      title={typeof action.tooltip === "function" ? action.tooltip(rowData.name) : action.tooltip}
                      onClick={() => {
                        action.action(rowData.id);
                      }}
                    >
                      {action.icon}
                    </button>
                  );
                })}
              </div>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  return (
    <>
      <BasicModal ref={addDuesModal}>
        {showAddDuesModal && (
          <MainBox className={`p-0 rounded ${classes["add-trainer-modal-container"]}`}>
            <div className={classes["add-trainer-modal"]}>
              <div className={classes["add-trainer-modal-header"]}>
                <h2 className={classes["add-trainer-modal-title"]}>{t("department_trainers.add_dues")}</h2>
                <img src={exit} alt="exit" className={classes["add-trainer-modal-exit"]} onClick={closeAddDuesModal} />
              </div>
              <div className={classes["add-trainer-modal-body"]}>
                <Formik
                  initialValues={{
                    value: "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setSubmitting(true);
                      // setLoading(true);
                      let data = {
                        ...values,
                        mechanism: "1",
                        trainer_id: selectedTrainer,
                        course_id: programCourses ? courseId : id,
                      };

                      let response = await addTrainerDues(data);
                      if (response.status === 201 && response.data.status) {
                        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
                        getTrainersApi();
                        setLoading(false);
                        setSubmitting(false);
                      } else {
                        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                      }
                    } catch (err) {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {err.response.data && err.response.data.errors.manager_trainer[0]}
                        </span>
                      );
                      setLoading(false);
                      setSubmitting(false);
                    }
                    closeAddDuesModal();
                  }}
                  validateOnChange={true}
                  validate={(values) => {
                    const errors = {};

                    // if (!values.mechanism) {
                    //   errors.mechanism = t("crud.errors.required");
                    // }

                    if (!values.value) {
                      errors.value = t("crud.errors.required");
                    }

                    // if (values.mechanism === "2" && values.value > 100) {
                    //   errors.value = t("department_trainers.percentage_error");
                    // }

                    return errors;
                  }}
                >
                  {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className={classes["add-trainer-modal-form"]}>
                      {/* <div className={classes["width-50"]} style={{ marginBottom: 20 }}>
                        <Select
                          label={t("department_trainers.mechanism")}
                          initialValue={t("department_trainers.mechanism")}
                          name="mechanism"
                          options={[
                            {
                              value: "1",
                              label: t("department_trainers.amount"),
                            },
                            {
                              value: "2",
                              label: t("department_trainers.percentage"),
                            },
                          ]}
                          value={values.mechanism}
                          onchange={handleChange}
                          error={errors.mechanism}
                          disabled={isSubmitting}
                        />
                      </div> */}
                      <div className={classes["width-100"]}>
                        <Input
                          label={t("department_trainers.value")}
                          placeholder={t("department_trainers.value")}
                          name="value"
                          value={values.value}
                          onChange={handleChange}
                          error={errors.value}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className={classes["add-trainer-modal__form-actions"]}>
                        <CustomButton
                          value={t("assginCourse.label.release")}
                          colors="#036c77"
                          type="submit"
                          loading={isSubmitting}
                          disable={isSubmitting}
                          classes={classes["add-trainer-modal__form-actions__btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      <MainTable columns={columns} data={trainers || []} loading={loading} />
    </>
  );
};

export default DepartmentsTrainersTable;
