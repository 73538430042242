import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import { applyCoupon } from "../../../../Services/api/courses/courseProvider";
import CourseDetailsHeader from "../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./trainingCoursePayment.module.css";

export default function CoursePaymentDetails({
  handleSubmit,
  courseDetails,
  validCoupon,
  setValidCoupon,
  values,
  errors,
  handleChange,
  setFieldValue,
  trainees,
  isGift,
}) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const [coursePrice, setCoursePrice] = useState(
    Number(courseDetails.is_program)
      ? courseDetails.payments.filter(
          (payment) => payment.is_payment_due === true
        )[0]?.amount || 0
      : courseDetails.price
  );
  const [activePaymentNumber, setActivePaymentNumber] = useState(
    Number(courseDetails.is_program) &&
      courseDetails.payments.findIndex(
        (payment) => payment.is_payment_due === true && !payment.is_paid
      ) + 1
  );

  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);
  const [priceAfterCoupon, setPriceAfterCoupon] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [opened, setOpened] = useState(true);

  const applyCouponCode = () => {
    setIsLoading(true);
    applyCoupon(courseDetails.id, couponCode)
      .then((res) => {
        if (
          res.status &&
          res.status === 200 &&
          res.data.status &&
          res.data.coupon
        ) {
          setValidCoupon({
            code: couponCode,
            value: res.data.coupon?.value,
            mechanism: res.data.coupon?.mechanism,
            include_tax: res.data.coupon?.include_tax,
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data.msg}
            </span>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
        setCouponCode("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeCoupon = () => {
    setValidCoupon({});
    setCouponCode("");
    setPriceAfterCoupon(0);
  };

  useEffect(() => {
    if (coursePrice) {
      let price = Number(coursePrice);
      if (
        courseDetails.offers.length > 0 &&
        courseDetails.offers[0].user_can_use_now
      ) {
        if (courseDetails.offers[0].mechanism === "1") {
          price -= Number(courseDetails.offers[0].value);
          setPriceAfterDiscount(price);
        } else {
          price -= (Number(courseDetails.offers[0].value) / 100) * price;
          setPriceAfterDiscount(price);
        }
      }
      if (Object.keys(validCoupon).length > 0) {
        if (validCoupon.mechanism === "1") {
          price -= Number(validCoupon.value);
          setPriceAfterCoupon(price);
        } else {
          price -= (Number(validCoupon.value) / 100) * price;
          setPriceAfterCoupon(price);
        }
      }
      if (courseDetails.taxes.length > 0) {
        courseDetails.taxes.forEach((tax) => {
          if (tax.user_can_use_now) {
            if (tax.mechanism === "1") {
              price += Number(tax.value);
            } else {
              // price += (Number(tax.value) / 100) * price;
              price +=
                (Number(tax.value) / 100) *
                (Object.keys(validCoupon).length === 0 ||
                validCoupon.include_tax
                  ? price
                  : priceAfterDiscount || coursePrice);
            }
          }
        });
      }
      setTotalPrice(Number(price).toFixed(2));
    }
  }, [courseDetails, validCoupon]);

  const calculateValue = (value, mechanism, price) => {
    if (mechanism === "1") {
      return value;
    } else {
      return Number((value / 100) * price).toFixed(2);
    }
  };

  let pricesList = [
    {
      id: "main_price",
      className: classes["payment-details-price-item"],
      title: t("payments.main_price"),
      value: coursePrice,
    },
    ...(courseDetails.offers.length > 0 &&
    courseDetails.offers[0].user_can_use_now
      ? [
          {
            id: "offer",
            className: classes["payment-details-price-item-minus"],
            title: courseDetails.offers[0].name,
            percentage:
              courseDetails.offers[0].mechanism === "2"
                ? `(${courseDetails.offers[0].value}%)`
                : null,
            value: calculateValue(
              courseDetails.offers[0].value,
              courseDetails.offers[0].mechanism,
              coursePrice
            ),
          },
        ]
      : []),
    ...(Object.keys(validCoupon).length > 0
      ? [
          {
            id: validCoupon.id,
            className: classes["payment-details-price-item-minus"],
            title: t("payments.coupon_code") + " " + validCoupon.code,
            percentage:
              validCoupon.mechanism === "2" ? `(${validCoupon.value}%)` : null,
            value: calculateValue(
              validCoupon.value,
              validCoupon.mechanism,
              priceAfterDiscount || coursePrice
            ),
            note: validCoupon.include_tax
              ? t("financial.label.include_tax")
              : null,
          },
        ]
      : []),
    ...((courseDetails.offers.length > 0 &&
      courseDetails.offers[0].user_can_use_now) ||
    Object.keys(validCoupon).length > 0
      ? [
          {
            id: "net_price",
            className: classes["payment-details-price-item"],
            title: t("payments.net_price"),
            value: priceAfterCoupon || priceAfterDiscount || coursePrice,
          },
        ]
      : []),
    ...(courseDetails.taxes.length > 0
      ? courseDetails.taxes
          .filter((tax) => tax.user_can_use_now)
          .map((tax) => {
            return {
              id: tax.id,
              className: classes["payment-details-price-item-plus"],
              title: tax.name,
              percentage: tax.mechanism === "2" ? `(${tax.value}%)` : null,
              value:
                Object.keys(validCoupon).length === 0 || validCoupon.include_tax
                  ? calculateValue(
                      tax.value,
                      tax.mechanism,
                      priceAfterCoupon || priceAfterDiscount || coursePrice
                    )
                  : calculateValue(
                      tax.value,
                      tax.mechanism,
                      priceAfterDiscount || coursePrice
                    ),
            };
          })
      : []),
  ];

  return (
    <div className={classes["payment-details"]}>
      {isGift && (
        <>
          <div className={classes["payment-field"]}>
            {/* <div className={classes["payment-details-title"]}>
              <label htmlFor="email">{t("trainee_list_label")}</label>
            </div>
            <div className={classes["traniee_msg"]}>
              <span>{t("trainee_list_label_msg")}</span>
            </div> */}
            <Input
              name="email"
              label={t("trainee_list_label")}
              id="email"
              type="email"
              placeholder={t("gifted_to")}
              onChange={handleChange}
              value={values.email}
              error={errors.email}
              required
            />
            {/* <p
              className={`form-input-error-space ${classes["select-field-error"]}`}
            >
              {errors?.email ? errors.email : null}
            </p> */}
          </div>{" "}
        </>
      )}
      {Number(courseDetails.is_program) ? (
        <div
          className={
            classes[`payment-info-container${opened ? "-opened" : ""}`]
          }
        >
          <CourseDetailsHeader
            title={
              t("payments.program_total_price") +
              " " +
              courseDetails.price +
              t("rs")
            }
            opened={opened}
            setOpened={setOpened}
          />
          {opened &&
            courseDetails.payments.map((payment, index) => (
              <div className={classes["payment-info-item-box"]}>
                <div className={classes["payment-info-item"]}>
                  <div className={classes["payment-info-item-title"]}>
                    {t("trainer.program.payment") + " " + (index + 1)}
                  </div>
                  <div className={classes["payment-info-item-value"]}>
                    {Number(payment.amount).toFixed(2)} {t("rs")}
                  </div>
                </div>
                <div className={classes["payment-info-item"]}>
                  <div className={classes["payment-info-item-title"]}></div>
                  <div className={classes["payment-info-item-value"]}>
                    {`${moment(payment.payment_date).format(
                      "DD/MM/YYYY"
                    )} - ${moment(payment.end_date).format("DD/MM/YYYY")}`}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : null}
      <div className={classes["payment-details-container"]}>
        <div className={classes["payment-details-title"]}>
          {!Number(courseDetails.is_program)
            ? `${t("payments.courseSummary")}`
            : `${t("payments.programSummary")} ${activePaymentNumber}`}
        </div>
        <div className={classes["payment-details-price-container"]}>
          {pricesList.map((price) => (
            <div className={price.className}>
              <div className={classes["payment-details-price"]}>
                <div className={classes["payment-details-price-top"]}>
                  <div className={classes["payment-details-price-title"]}>
                    {price.title}
                  </div>
                  {price.percentage && (
                    <div
                      className={classes["payment-details-price-percentage"]}
                    >
                      {price.percentage}
                    </div>
                  )}
                </div>
                {price.note && (
                  <div className={classes["payment-details-price-note"]}>
                    {price.note}
                  </div>
                )}
              </div>
              <div className={classes["payment-details-price-value"]}>
                {Number(price.value).toFixed(2)} {t("rs")}
              </div>
            </div>
          ))}
          <div className={classes["payment-details-total-price-item"]}>
            <div className={classes["payment-details-total-price-title"]}>
              {t("payments.total_price")}
              <span
                className={classes["payment-details-total-price-title-span"]}
              >
                {" "}
                ({t("payments.include_tax")})
              </span>
            </div>
            <div className={classes["payment-details-total-price-value"]}>
              {totalPrice} {t("rs")}
            </div>
          </div>
        </div>
        <div className={classes["payment-details-coupon-container"]}>
          <Input
            label={t("payments.coupon_code")}
            className={classes["payment-details-coupon-input"]}
            placeholder={t("payments.coupon_code")}
            value={validCoupon?.code ? validCoupon.code : couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          {Object.keys(validCoupon).length > 0 ? (
            <CustomButton
              action={removeCoupon}
              type="button"
              disable={false}
              colors="#036c77"
              value={t("general.cancellation")}
              classes={classes["payment-details-coupon-button"]}
            />
          ) : (
            <CustomButton
              action={applyCouponCode}
              type="button"
              disable={couponCode === "" || Object.keys(validCoupon).length > 0}
              colors="#036c77"
              value={t("payments.apply")}
              classes={`${classes["payment-details-coupon-button"]} ${
                couponCode === "" || Object.keys(validCoupon).length > 0
                  ? classes["payment-details-coupon-button-disabled"]
                  : ""
              }`}
            />
          )}
        </div>
        <CustomButton
          action={handleSubmit}
          disable={false}
          colors="#036c77"
          value={t("payments.pay")}
          classes={classes["payment-details-pay-button"]}
        />
      </div>
      {isLoading && <SkeletonCardOverlay />}
    </div>
  );
}
