import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";
import Coupons from "../../Modules/Admin/Coupon/Coupons/Coupons";
import CouponList from "../../Modules/Admin/Coupon/couponList/CouponList";
import AssignUsersToDepartment from "../../Modules/Admin/Department/AssignUsers/AssignUsers";
import CourseTraineeStatement from "../../Modules/Admin/Departments/DepartmentsTrainees/CourseTraineeStatement/CourseTraineeStatement";
import CourseTrainerStatement from "../../Modules/Admin/Departments/DepartmentsTrainers/CourseTrainerStatement/CourseTrainerStatement";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import BoothDetails from "../../Modules/Admin/Exhibition/BoothDetails/BoothDetails";
import CreateExhibition from "../../Modules/Admin/Exhibition/CreateExhibition/CreateExhibition";
import CreateExhibitionPage from "../../Modules/Admin/Exhibition/CreateExhibitionPage";
import DraftBoothDetails from "../../Modules/Admin/Exhibition/DraftBoothDetails/DraftBoothDetails";
import EditExhibitionPage from "../../Modules/Admin/Exhibition/EditExhibitionPage";
import ExhibitionDetails from "../../Modules/Admin/Exhibition/ExhibitionDetails/ExhibitionDetails";
import ExhibitionList from "../../Modules/Admin/Exhibition/ExhibitionList/ExhibitionList";
import ExhibitionReport from "../../Modules/Admin/Exhibition/ExhibitionReport";
import Fund from "../../Modules/Admin/Financial/fund/fund";
import WalletDetails from "../../Modules/Admin/Financial/userWallet/walletDetails/walletDetails";
import Statistics from "../../Modules/Admin/Statistics/Statistics";
import SystemUserEdit from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUserDetails/SystemUserEdit";
import SystemUserPreview from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUserDetails/SystemUserPreview";
import SystemUsersList from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUsersList";
import TrainersRatting from "../../Modules/Admin/TrainersRatting/TrainersRatting";
import AddAdvertisement from "../../Modules/Admin/advertisement/AddAdvetisement";
import Advertisements from "../../Modules/Admin/advertisement/Advertisement";
import EditAdvertisement from "../../Modules/Admin/advertisement/EditAdvertisement";
import GuideLinesEdit from "../../Modules/Admin/guideLines/GuideLinesEdit";
import Booth from "../../Modules/Exhibition/Booth";
import FAQ from "../../Modules/FAQ_s/FAQ";
import RateTicket from "../../Modules/Shared/Components/Tickets/Rate/Rate";
import AddCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Add";
import EditCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Edit";
import CourseTeamsList from "../../Modules/Trainer/Course/CourseTeams/List";
import DiscBoardParticipants from "../../Modules/Trainer/Course/discBoard/Participants/DiscBoardParticipants";
import DiscBoardStatement from "../../Modules/Trainer/Course/discBoard/Statement";
import { VoteAdd, VoteEdit } from "../../Modules/Trainer/Course/discBoard/Vote";
import { Activity } from "../trainers";
import {
  AboutUs,
  AboutUsEdit,
  AcrivitiesMark,
  ActiveAccount,
  AdSponsor,
  AddCategory,
  AddCertificateTemplates,
  AddCoupon,
  AddCourse,
  AddDepartment,
  AddEduGoals,
  AddFaq,
  AddIpBlocked,
  AddNewTrainee,
  AddOffers,
  AddQuestionToQuestionBankWrapper,
  AddRefundSetting,
  AddSponsor,
  AddSurvey,
  AddTax,
  AddTicket,
  AddTicketsType,
  AddTraineeByEmail,
  AddTraineeByName,
  Analysis,
  AnswersReply,
  AssessmentAdd,
  AssessmentAnswers,
  AssessmentEdit,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
  AssignTicketTypeToUser,
  BankTransfers,
  BoothsBookingDetails,
  BoothsBookingList,
  BoothsList,
  CertificateTemplates,
  CouponDetails,
  CourseAnalysis,
  CourseDetails,
  CourseDueReport,
  CourseListTrainee,
  CourseListTrainer,
  CoursePartners,
  CourseProfile,
  CoursesList,
  CreateAccount,
  CreateCoupon,
  CreateDepartment,
  CreateSpecialties,
  Dashboard,
  DepartmentsList,
  DepartmentsTrainees,
  DepartmentsTrainers,
  DetialedReport,
  DiscBoard,
  Edit,
  EditCategory,
  EditCertificateTemplates,
  EditCoupon,
  EditCourse,
  EditDepartment,
  EditEduGoals,
  EditFaq,
  EditIpBlocked,
  EditOffers,
  EditRefundSetting,
  EditRole,
  EditSpecialties,
  EditTax,
  EditTicketsType,
  EditTrainer,
  EduGoals,
  Exhibition,
  ExhibitionsList,
  FaqList,
  GenericNotFound,
  IpBlocked,
  IpBlockedRequest,
  LearningActivityRegisteredList,
  LearningAnalysisWrapper,
  LecturersCommitment,
  LessonCanvas,
  ListCategories,
  ListDepartments,
  ListQuestionBank,
  ListRoles,
  ListSurvey,
  ListTicketTypes,
  ListTickets,
  Logs,
  Offers,
  PartnerPogramCourses,
  PartnersTebx,
  Payments,
  PercentageTrainersList,
  PeymentRequestsCoursesPrograms,
  PreviewIpBlocked,
  PrivacyPolicy,
  ProgramAdd,
  ProgramEdit,
  ProgramList,
  ProgramPaymentDate,
  ProgramPaymentList,
  ProgramPaymentListFinancial,
  ProgramPaymentsReport,
  QuizAnalysis,
  RatesDetails,
  Refund,
  RefundSetting,
  RefundSettingList,
  ReportFinancial,
  ReportHoursTrainee,
  ReportHoursTrainer,
  ReportTraineeCourses,
  ReportTraineePrograms,
  ReportTrainer,
  Search,
  SearchFree,
  SearchHistory,
  SearchHistoryDetails,
  SearchPreview,
  SendMessage,
  SpecialtiesDetails,
  SpecialtiesList,
  SponserExhibition,
  SponsersList,
  Sponsor,
  Sponsors,
  StatisticsSurvey,
  SupportUsers,
  SurveyAnswers,
  SurveyParticipants,
  SurveyQuestions,
  SurveyResponse,
  SurveyView,
  SystemPreferences,
  Tax,
  TermsAndConditions,
  TotalIncomeReport,
  TraieeAnalysis,
  TraineeAnswers,
  TraineeCertificates,
  TraineeChapter,
  TraineeCourseLesson,
  TraineeList,
  TrainerList,
  TrainerRates,
  TypeFileAdd,
  TypeFileEdit,
  TypeFileList,
  UpdateAnswersReply,
  UpdateSupportUsers,
  UserWallet,
  ViewTicket,
  WebxAddToken,
  WebxListToken,
  WebxUpdateToken,
  WelcomeMessage,
  chat,
} from "./";

const routesAdmin = [
  { component: Dashboard, path: "/", exact: true },
  {
    component: () => <DiscBoard role="admin" type="lesson" />,
    path: "/course/:courseId/activity/:id",
    exact: true,
  },
  {
    component: () => <LecturersCommitment isAdmin={true} />,
    path: "/lecturers-commitment",
  },
  {
    component: () => <CertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates",
    exact: true,
  },
  {
    component: () => <AddCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/add",
    exact: true,
  },
  {
    component: () => <AddCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/:id/add",
    exact: true,
  },
  {
    component: () => <CertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/:id",
    exact: true,
  },
  {
    component: () => <CertificateTemplates isAdmin={true} isProgram={true} />,
    path: "/program/certificateTemplates/:id",
    exact: true,
  },
  {
    component: () => <EditCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/edit/:id",
    exact: true,
  },
  {
    component: () => <TraineeCertificates isAdmin={true} />,
    path: "/trainee/:id/certificates",
    exact: true,
  },
  {
    component: () => <TraineeCertificates isAdmin={true} />,
    path: "/trainee/:id/:source/certificates",
    exact: true,
  },
  {
    component: () => <PartnerPogramCourses isProgram={true} isAdmin={true} />,
    path: "/partner/:id/programs-list",
    exact: true,
  },
  {
    component: () => <PartnerPogramCourses isAdmin={true} />,
    path: "/partner/:id/courses-list",
    exact: true,
  },
  {
    component: CreateSpecialties,
    path: "/category/add",
    exact: true,
  },
  {
    component: SponserExhibition,
    path: "/sponser-exhibition/:id",
    exact: true,
  },
  {
    component: PartnersTebx,
    path: "/partners-tebx",
    exact: true,
  },
  {
    component: SponsersList,
    path: "/sponsers",
    exact: true,
  },
  {
    component: () => <DetialedReport isAdmin={true} isAll={true} />,
    path: "/all-detailed-report",
    exact: true,
  },
  {
    component: () => <DetialedReport isAdmin={true} />,
    path: "/detailed-report",
    exact: true,
  },
  {
    component: () => <AcrivitiesMark role="admin" />,
    path: "/course/lesson/:id/:activityId/activities-grades",
    exact: true,
  },

  {
    component: CreateDepartment,
    path: "/department/add",
    exact: true,
  },
  {
    component: CreateCoupon,
    path: "/coupon/add",
    exact: true,
  },

  {
    component: AddTicketsType,
    path: "/ticket/type/add",
    exact: true,
  },
  {
    component: EditSpecialties,
    path: "/category/edit/:id",
    exact: true,
  },
  {
    component: EditDepartment,
    path: "/department/edit/:id",
    exact: true,
  },
  {
    component: EditCoupon,
    path: "/coupon/edit/:id",
    exact: true,
  },
  {
    component: () => <CouponDetails type="notGift" />,
    path: "/coupon/:id",
    exact: true,
  },
  {
    component: () => <CouponDetails type="gift" />,
    path: "/gift-coupon/:id",
    exact: true,
  },
  {
    component: EditRole,
    path: "/role/:id",
    exact: true,
  },
  {
    component: AddTicketsType,
    path: "/ticket/type/edit/:id",
    exact: true,
  },
  {
    component: SpecialtiesList,
    path: "/category",
    exact: true,
  },
  {
    component: SpecialtiesDetails,
    path: "/category/:id",
    exact: true,
  },
  {
    component: DepartmentsTrainees,
    path: "/category/:id/trainees",
    exact: true,
  },
  {
    component: DepartmentsTrainers,
    path: "/category/:id/trainers",
    exact: true,
  },
  {
    component: () => <CoursesList role="admin" notInProgram={true} />,
    path: "/courses",
    exact: true,
  },
  {
    component: () => <AddCourse role="admin" />,
    path: "/course/add",
    exact: true,
  },
  {
    component: () => <EditCourse role="admin" />,
    path: "/course/edit/:id",
    exact: true,
  },
  {
    component: CourseDetails,
    path: "/course/preview/:id",
    exact: true,
  },
  {
    component: () => <DiscBoard role="admin" />,
    path: "/course/:courseId/discussion-board/:id",
    exact: true,
  },
  {
    component: () => <DiscBoardParticipants role="admin" />,
    path: "/course/:courseId/discussion-board/:id/participants",
    exact: true,
  },
  {
    component: () => <VoteAdd role="admin" />,
    path: "/course/:courseId/discussion-board/:discussionId/votes/add",
    exact: true,
  },
  {
    component: () => <VoteEdit role="admin" />,
    path: "/course/:courseId/discussion-board/:discussionId/votes/:id/edit",
    exact: true,
  },
  {
    component: () => <DiscBoardStatement role="admin" />,
    path: "/course/:courseId/discussions",
    exact: true,
  },
  {
    component: () => <DiscBoard role="admin" />,
    path: "/course/:courseId/discussion-board",
    exact: true,
  },
  {
    component: CourseProfile,
    path: "/course/profile/:id",
    exact: true,
  },
  {
    component: DepartmentsTrainers,
    path: "/course/:id/trainers",
    exact: true,
  },
  {
    component: DepartmentsTrainees,
    path: "/course/:id/trainees",
    exact: true,
  },
  {
    component: (props) => <CourseTraineeStatement {...props} role={"admin"} />,
    path: "/course/:courseId/trainees/:id",
    exact: true,
  },
  {
    component: (props) => (
      <CourseTraineeStatement {...props} role={"admin"} programCourses={true} />
    ),
    path: "/program/:programId/course/:courseId/trainees/:id",
    exact: true,
  },
  {
    component: (props) => <CourseTrainerStatement {...props} role={"admin"} />,
    path: "/course/:courseId/trainers/:id",
    exact: true,
  },
  {
    component: (props) => (
      <CourseTrainerStatement {...props} role={"admin"} isProgram={true} />
    ),
    path: "/program/:courseId/trainers/:id",
    exact: true,
  },
  {
    component: (props) => (
      <CourseTrainerStatement {...props} role={"admin"} programCourses={true} />
    ),
    path: "/program/:programId/course/:courseId/trainers/:id",
    exact: true,
  },
  {
    component: (props) => <TrainersRatting {...props} role={"admin"} />,
    path: "/course/:courseId/trainers-ratting/:id",
    exact: true,
  },
  {
    component: (props) => (
      <TrainersRatting {...props} role={"admin"} isProgram={true} />
    ),
    path: "/program/:courseId/trainers-ratting/:id",
    exact: true,
  },
  {
    component: (props) => (
      <TrainersRatting {...props} role={"admin"} programCourses={true} />
    ),
    path: "/program/:programId/course/:courseId/trainers-ratting/:id",
    exact: true,
  },
  {
    component: CoursePartners,
    path: "/course/:id/partners",
    exact: true,
  },
  {
    component: () => <Payments type="course" />,
    path: "/course/:id/trainees-payments",
    exact: true,
  },
  {
    component: PeymentRequestsCoursesPrograms,
    path: "/peyment-requests-courses",
    exact: true,
  },
  {
    component: () => <PeymentRequestsCoursesPrograms isProgram={true} />,
    path: "/peyment-requests-programs",
    exact: true,
  },
  {
    component: () => <Payments type="financial" />,
    path: "/financial-movement",
    exact: true,
  },

  {
    component: () => <CourseTeamsList isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/teams",
    exact: true,
  },
  {
    component: () => <AddCourseTeam isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/teams/add",
    exact: true,
  },
  {
    component: () => <EditCourseTeam isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/teams/:id/edit",
    exact: true,
  },
  {
    component: (props) => (
      <AddQuestionToQuestionBankWrapper isAdmin={true} role={"admin"} />
    ),
    path: "/course/:courseId/question-bank/add",
    exact: true,
  },
  {
    component: () => (
      <AddQuestionToQuestionBankWrapper
        editMode={true}
        isAdmin={true}
        role={"admin"}
      />
    ),
    path: "/course/:courseId/question-bank/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListQuestionBank isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/question-bank",
    exact: true,
  },
  {
    component: (props) => <EduGoals isAdmin={true} role={"admin"} />,
    path: "/course/educational-goals/:id",
    exact: true,
  },
  {
    component: (props) => <AddEduGoals isAdmin={true} role={"admin"} />,
    path: "/course/educational-goals/add/:id",
    exact: true,
  },
  {
    component: (props) => <EditEduGoals isAdmin={true} role={"admin"} />,
    path: "/course/educational-goals/edit/:id",
    exact: true,
  },
  {
    component: () => <Statistics isAdmin={true} />,
    path: "/statistics",
    exact: true,
  },
  {
    component: () => <Advertisements isAdmin={true} />,
    path: "/advertisements-list",
    exact: true,
  },
  {
    component: () => <AddAdvertisement isAdmin={true} />,
    path: "/advertisements-list/add",
    exact: true,
  },
  {
    component: () => <EditAdvertisement isAdmin={true} />,
    path: "/advertisements-list/:id/edit",
    exact: true,
  },
  {
    component: (props) => (
      <LessonCanvas role={"admin"} isAdmin={true} isTrainer={true} />
    ),
    path: "/course/:courseId/lesson/:id",
    exact: true,
  },
  {
    component: () => <CoursesList role="admin" isProgram={true} />,
    path: "/program/list",
    exact: true,
  },
  {
    component: () => <AddCourse isProgram={true} role="admin" />,
    path: "/program/add",
    exact: true,
  },
  {
    component: () => <EditCourse isProgram={true} role="admin" />,
    path: "/program/edit/:id",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers isProgram={true} />,
    path: "/program/:id/trainers",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainees isProgram={true} />,
    path: "/program/:id/trainees",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainees programCourses={true} />,
    path: "/program/:id/course/:courseId/trainees",
    exact: true,
  },
  {
    component: () => <CoursePartners isProgram={true} />,
    path: "/program/:id/partners",
    exact: true,
  },
  {
    component: () => <Payments type="program" isProgram={true} />,
    path: "/program/:id/trainees-payments",
    exact: true,
  },
  {
    component: () => <ProgramPaymentsReport role="admin" />,
    path: "/program/:id/payments-report",
  },
  {
    component: () => (
      <CoursesList
        role="admin"
        programCourses={true}
        previewRoute={"inprogram"}
      />
    ),
    path: "/program/:id/courses",
    exact: true,
  },
  {
    component: () => <ProgramPaymentList isAdmin={true} />,
    path: "/program/:id/payments",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers programCourses={true} />,
    path: "/program/:id/course/:courseId/trainers",
    exact: true,
  },
  {
    component: () => <AddCourse programCourses={true} role="admin" />,
    path: "/program/:id/course/add",
    exact: true,
  },
  {
    component: () => <EditCourse programCourses={true} role="admin" />,
    path: "/program/:id/course/edit/:courseId",
    exact: true,
  },
  {
    component: DepartmentsList,
    path: "/department",
    exact: true,
  },
  {
    component: SystemUsersList,
    path: "/roles",
    exact: true,
  },
  {
    component: SystemUserPreview,
    path: "/roles/:id",
    exact: true,
  },
  {
    component: SystemUserEdit,
    path: "/roles/edit/:id",
    exact: true,
  },
  {
    component: EvaluationList,
    path: "/evaluation",
    exact: true,
  },
  {
    component: SendMessage,
    path: "/message/send",
    exact: true,
  },
  {
    component: ListTicketTypes,
    path: "/ticket/types",
    exact: true,
  },
  {
    component: AboutUs,
    path: "/about-us",
    exact: true,
  },
  {
    component: AboutUsEdit,
    path: "/about-us-edit",
    exact: true,
  },
  {
    component: ActiveAccount,
    path: "/active-account",
    exact: true,
  },
  {
    component: CreateAccount,
    path: "/create-account",
    exact: true,
  },
  {
    component: () => <BankTransfers role={"admin"} />,
    path: "/bank-transfers",
    exact: false,
  },
  {
    component: GenericNotFound,
    path: "/page-not-found",
    exact: false,
  },
  {
    component: () => <Coupons type="notGift" />,
    path: "/coupon",
    exact: true,
  },
  {
    component: () => <Coupons type="gift" />,
    path: "/gift-coupon",
    exact: true,
  },
  {
    component: IpBlocked,
    path: "/ip-blocked",
    exact: true,
  },
  {
    component: AddIpBlocked,
    path: "/ip-blocked/add",
    exact: true,
  },
  {
    component: EditIpBlocked,
    path: "/ip-blocked/edit/:id",
    exact: true,
  },
  {
    component: PreviewIpBlocked,
    path: "/ip-blocked/preview/:id",
    exact: true,
  },
  {
    component: IpBlockedRequest,
    path: "/ip-blocked/requests",
    exact: true,
  },
  {
    component: AssignTicketTypeToUser,
    path: "/ticket/type/assign/:id",
    exact: true,
  },
  {
    component: AddTicket,
    path: "/ticket/add",
    exact: true,
  },
  {
    component: ViewTicket,
    path: "/ticket/view/:id",
    exact: true,
  },
  {
    component: () => <ListTickets isPublic={false} />,
    path: "/tickets",
    exact: true,
  },
  {
    component: SystemPreferences,
    path: "/preferences",
    exact: true,
  },
  {
    component: CourseListTrainee,
    path: "/course/trainee/list",
    exact: true,
  },
  {
    component: CourseListTrainer,
    path: "/course/trainer/list",
    exact: true,
  },
  {
    component: TrainerList,
    path: "/course/trainer/add/:id",
    exact: true,
  },
  {
    component: TraineeList,
    path: "/course/trainee/add/:id",
    exact: true,
  },
  {
    component: AddFaq,
    path: "/add-faq",
    exact: true,
  },
  {
    component: FaqList,
    path: "/faq-list",
    exact: true,
  },
  {
    component: FAQ,
    path: "/faq",
    exact: true,
  },
  {
    component: EditFaq,
    path: "/edit-faq/:id",
    exact: true,
  },
  {
    component: RateTicket,
    path: "/ticket/rate/:id",
    exact: true,
  },
  {
    component: AssignUsersToDepartment,
    path: "/department/:id/assign/users",
    exact: true,
  },
  {
    component: TypeFileList,
    path: "/typefile/list",
    exact: true,
  },
  {
    component: TypeFileEdit,
    path: "/typefile/edit/:id",
    exact: true,
  },
  {
    component: TypeFileAdd,
    path: "/typefile/add",
    exact: true,
  },
  {
    component: ReportTrainer,
    path: "/report/trainer/list",
    exact: true,
  },
  {
    component: ReportHoursTrainer,
    path: "/report/trainer/hours/list",
    exact: true,
  },
  {
    component: ReportTraineeCourses,
    path: "/report/trainee-courses/list",
    exact: true,
  },
  {
    component: ReportTraineePrograms,
    path: "/report/trainee-programs/list",
    exact: true,
  },
  {
    component: ReportHoursTrainee,
    path: "/report/trainee/hours/list",
    exact: true,
  },
  {
    component: ReportFinancial,
    path: "/financial/report",
    exact: true,
  },
  {
    component: TotalIncomeReport,
    path: "/financial/total-income-report",
    exact: true,
  },
  {
    component: CourseDueReport,
    path: "/financial/course-due-report",
    exact: true,
  },
  {
    component: Offers,
    path: "/offer",
    exact: true,
  },
  {
    component: AddOffers,
    path: "/offer/add",
    exact: true,
  },
  {
    component: EditOffers,
    path: "/offer/edit/:id",
    exact: true,
  },
  {
    component: WebxAddToken,
    path: "/webx/add-token",
    exact: true,
  },
  {
    component: WebxListToken,
    path: "/webx/list",
    exact: true,
  },
  {
    component: WebxUpdateToken,
    path: "/webx/update/:id",
    exact: true,
  },
  {
    component: Tax,
    path: "/tax",
    exact: true,
  },
  {
    component: AddTax,
    path: "/tax/add",
    exact: true,
  },
  {
    component: EditTax,
    path: "/tax/edit/:id",
    exact: true,
  },
  {
    component: () => <CreateExhibitionPage role={"admin"} />,
    path: "/create-exhibition",
    exact: true,
  },
  {
    component: () => <CreateExhibitionPage role={"admin"} />,
    path: "/course/:courseId/lesson/:lessonId/exhibition/add",
    exact: true,
  },
  {
    component: () => <ExhibitionDetails role={"admin"} />,
    path: "/course/:courseId/lesson/:lessonId/exhibition/:id",
    exact: true,
  },
  {
    component: ExhibitionDetails,
    path: "/exhibition/:id",
    exact: true,
  },
  {
    component: ExhibitionList,
    path: "/exhibitions-list",
    exact: true,
  },
  {
    component: EditExhibitionPage,
    path: "/edit-exhibition/:id",
    exact: true,
  },
  {
    component: ExhibitionReport,
    path: "/exhibition-report/:id",
    exact: true,
  },
  {
    component: () => <Refund type="trainee" />,
    path: "/refund",
    exact: true,
  },
  {
    component: () => <Refund type="exhibition-visitors" />,
    path: "/refund/exhibition-visitors",
    exact: true,
  },
  {
    component: () => <Refund type="exhibition-booths" />,
    path: "/refund/exhibition-booths",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => (
      <AddSurvey {...props} fromTrainer={true} role={"admin"} />
    ),
    path: "/survey/trainer/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} role={"admin"} />,
    path: "/survey/list",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} role={"admin"} />,
    path: "/course/:targetCourseId/survey/list",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/course/:targetCourseId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/course/:targetCourseId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"admin"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView role={"admin"} preview={true} editor={true} />
    ),
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} fromTrainer={true} />,
    path: "/survey/trainer/list",
    exact: true,
  },
  {
    component: (props) => <SurveyParticipants role="admin" />,
    path: "/survey/:id/participants",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyParticipants role="admin" {...props} fromTrainer={true} />
    ),
    path: "/survey/trainer/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse />,
    path: "/survey/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse {...props} fromTrainer={true} />,
    path: "/survey/trainer/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView role={"admin"} preview={true} editor={true} />
    ),
    path: "/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView role={"admin"} preview={true} editor={true} />
    ),
    path: "/course/:targetCourseId/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView role={"admin"} preview={true} editor={true} />
    ),
    path: "/survey/trainer/preview/:id",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyView
        role={"admin"}
        preview={true}
        editor={true}
        fromTrainer={true}
      />
    ),
    path: "/course/:targetCourseId/survey/trainer/preview/:id",
    exact: true,
  },

  {
    component: TraineeAnswers,
    path: "/survey/trainee-trainer/answers",
    exact: true,
  },
  {
    component: StatisticsSurvey,
    path: "/survey/statistics/:id",
    exact: true,
  },
  {
    component: chat,
    path: "/livechat",
    exact: true,
  },
  {
    component: AnswersReply,
    path: "/livechat/answers-reply",
    exact: true,
  },
  {
    component: UpdateAnswersReply,
    path: "/livechat/create-answers-reply",
    exact: true,
  },
  {
    component: UpdateAnswersReply,
    path: "/livechat/edit-answers-reply/:id",
    exact: true,
  },
  {
    component: WelcomeMessage,
    path: "/livechat/welcome-message",
    exact: true,
  },
  {
    component: SupportUsers,
    path: "/livechat/support-users",
    exact: true,
  },
  {
    component: UpdateSupportUsers,
    path: "/livechat/create-support-users",
    exact: true,
  },
  {
    component: UpdateSupportUsers,
    path: "/livechat/edit-support-users/:id",
    exact: true,
  },
  {
    component: RefundSetting,
    path: "/refund-setting",
    exact: true,
  },
  {
    component: ProgramPaymentListFinancial,
    path: "/program-payment-date",
    exact: true,
  },
  {
    component: Sponsors,
    path: "/sponsors",
    exact: true,
  },
  {
    component: AddSponsor,
    path: "/sponsor/addsponsor",
    exact: true,
  },
  {
    component: AddTraineeByName,
    path: "/addtraineebyname",
    exact: true,
  },
  {
    component: AddTraineeByEmail,
    path: "/addtraineebyemail",
    exact: true,
  },
  {
    component: Analysis,
    path: "/analysis",
    exact: true,
  },
  {
    component: UserWallet,
    path: "/users-wallet",
    exact: true,
  },
  {
    component: Fund,
    path: "/fund",
    exact: true,
  },
  {
    component: WalletDetails,
    path: "/users-wallet/details/:id",
    exact: true,
  },
  {
    component: Logs,
    path: "/logs",
    exact: true,
  },
  {
    component: TraieeAnalysis,
    path: "/trainee-analysis",
    exact: true,
  },
  {
    component: TraineeCourseLesson,
    path: "/trainee-analysis/:userId/:courseId",
    exact: true,
  },
  {
    component: TraineeChapter,
    path: "/trainee-chapter-analysis",
    exact: true,
  },
  {
    component: Sponsor,
    path: "/sponsor",
    exact: true,
  },
  {
    component: AdSponsor,
    path: "/add/sponsor",
    exact: true,
  },
  {
    component: AddNewTrainee,
    path: "/add/sponsor/:id",
    exact: true,
  },
  {
    component: TrainerRates,
    path: "/trainer-rate/list",
    exact: true,
  },
  {
    component: PercentageTrainersList,
    path: "/percentage-trainer/list",
    exact: true,
  },
  {
    component: EditTrainer,
    path: "/percentage-trainer/edit",
    exact: true,
  },
  {
    component: BoothsList,
    path: "/exhibition/:id/booths",
    exact: true,
  },
  {
    component: BoothsBookingList,
    path: "/exhibition/:id/booths-booking-list",
    exact: true,
  },
  {
    component: BoothsBookingDetails,
    path: "/exhibition/booths-booking/:id",
    exact: true,
  },
  {
    component: CourseAnalysis,
    path: "/course-analysis",
    exact: true,
  },
  {
    component: (props) => <LearningAnalysis {...props} role={"admin"} />,
    path: "/learning-analysis",
    exact: true,
  },
  {
    component: QuizAnalysis,
    path: "/course-analysis/:id/quiz-analysis/:quizId",
    exact: true,
  },
  {
    component: () => <RefundSettingList type="trainee" />,
    path: "/refund-setting/list",
    exact: true,
  },
  {
    component: () => <RefundSettingList type="exhibition-visitors" />,
    path: "/refund-setting/list/exhibition-visitors",
    exact: true,
  },
  {
    component: () => <RefundSettingList type="exhibition-booths" />,
    path: "/refund-setting/list/exhibition-booths",
    exact: true,
  },
  {
    component: () => <AddRefundSetting type="trainee" />,
    path: "/refund-setting/add",
    exact: true,
  },
  {
    component: () => <AddRefundSetting type="exhibition-visitors" />,
    path: "/refund-setting/exhibition-visitors/add",
    exact: true,
  },
  {
    component: () => <AddRefundSetting type="exhibition-booths" />,
    path: "/refund-setting/exhibition-booths/add",
    exact: true,
  },
  {
    component: () => <EditRefundSetting type="trainee" />,
    path: "/refund-setting/edit/:id",
    exact: true,
  },
  {
    component: () => <EditRefundSetting type="exhibition-visitors" />,
    path: "/refund-setting/exhibition-visitors/edit/:id",
    exact: true,
  },
  {
    component: () => <EditRefundSetting type="exhibition-booths" />,
    path: "/refund-setting/exhibition-booths/edit/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyQuestions {...props} role="admin" />,
    path: "/survey/:id/questions",
    exact: true,
  },
  {
    component: (props) => (
      <SurveyQuestions {...props} fromTrainer role="admin" />
    ),
    path: "/survey/trainer/:id/questions",
    exact: true,
  },
  {
    component: SurveyAnswers,
    path: "/survey/:surveyId/questions/:questionId/answers",
    exact: true,
  },
  {
    component: RatesDetails,
    path: "/trainer-rate/list/:id/course/:courseId",
    exact: true,
  },
  // {
  //   component: LearningAnalysisWrapper,
  //   path: "/learning-analysis",
  //   exact: true,
  // },
  {
    component: LearningActivityRegisteredList,
    path: "/learning-analysis/registered/:courseId?",
    exact: true,
  },
  {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    exact: true,
  },
  {
    component: GuideLinesEdit,
    path: "/guidelines-edit",
    exact: true,
  },
  {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    exact: true,
  },
  {
    component: BoothDetails,
    path: "/booth-details/:id",
    exact: true,
  },
  {
    component: DraftBoothDetails,
    path: "/draft-booth-details/:id",
    exact: true,
  },
  {
    component: Booth,
    path: "/booth-edit/:id",
    exact: true,
  },
  {
    component: Search,
    path: "/search",
    exact: true,
  },
  {
    component: SearchPreview,
    path: "/search/preview/:id",
    exact: true,
  },
  {
    component: SearchHistory,
    path: "/search/history",
    exact: true,
  },
  {
    component: SearchHistoryDetails,
    path: "/search/history/:id",
    exact: true,
  },
  {
    component: SearchFree,
    path: "/search/free",
    exact: true,
  },
  {
    component: () => <AssessmentsList quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsList quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/assessments",
    exact: true,
  },
  {
    component: () => <ActivitiesStatement isAdmin={true} role={"admin"} />,
    path: "/course/:courseId/activities",
    exact: true,
  },

  {
    component: () => <EvaluationList quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList role={"admin"} />,
    path: "/course/:courseId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"chapter"} role={"admin"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => (
      <AssessmentParticipants quizable={"lesson"} role={"admin"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => <AssessmentAnswers quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => <AssessmentAnswers quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"chapter"} role={"admin"} />
    ),
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => (
      <AssessmentStatistics quizable={"lesson"} role={"admin"} />
    ),
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => <AssessmentPreview quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => <AssessmentPreview quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"chapter"} role={"admin"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"lesson"} role={"admin"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => <CourseProfile isAdmin={true} role={"admin"} />,
    path: "/course/:id",
    exact: true,
  },
  {
    component: () => <CourseProfile role="admin" inProgramCourses={true} />,
    path: "/program/:programId/course/:id",
    exact: true,
  },
];

export default routesAdmin;
