import { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { exhibitionVisit } from "../../Redux/Actions/exhibition/exhibition.action";
import { getExhibitionList, getExhibitionListGuest } from "../../Services/api/Exhibition/ExhibitionProvider";
import booth from "../../assets/icons/boothgreen.svg";
import calendar from "../../assets/icons/calendar.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import gallery from "../../assets/icons/gallery.svg";
import greenEye from "../../assets/icons/greenEye.svg";
import plus from "../../assets/icons/plus.svg";
import warning from "../../assets/icons/warning-green.svg";
import Badge from "../Shared/Components/Badge/Badge";
import Card from "../Shared/Components/Card/Card";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import IconWithText from "../Shared/Components/IconWithText/IconWithText";
import ImageWithLabel from "../Shared/Components/ImageWithLabel/ImageWithLabel";
import Logo from "../Shared/Components/Logo/Logo";
import MainBox from "../Shared/Components/MainBox/MainBox";
import MainCarousel from "../Shared/Components/MainCarousel/MainCarousel";
import { BasicModal } from "../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRating from "../Shared/Components/StarsRating/StarsRating";
import Title from "../Shared/Components/Title/Title";
import WarningModal from "../Shared/Components/WarningModal/WarningModal";
import FreeExhibitionForGuestModal from "./FreeExhibitionForGuestModal";

export default function Exhibition() {
  require("./exhibition.css");
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLogged = localStorage.getItem("token");
  const roles = JSON.parse(localStorage.getItem("roles"));

  const freeExhibitionForGuestRef = useRef();
  const paidExhibitionForGuestRef = useRef();
  const imgModalRef = useRef();
  const [showFreeExhibitionForGuest, setShowFreeExhibitionForGuest] = useState(false);
  const [showPaidExhibitionForGuest, setShowPaidExhibitionForGuest] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [exhibition, setExhibition] = useState([]);
  const [meta, setMeta] = useState([]);
  const [photos, setPhotos] = useState(false);
  const [exhibitionId, setExhibitionId] = useState();
  const [loadMoreButton, setLoadMoreButton] = useState();

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day].join("-");
  };

  const fetchExhibitions = async (params = { perPage: 9 }) => {
    const fetchExhibitionsAPI = isLogged ? getExhibitionList : getExhibitionListGuest;

    try {
      const res = await fetchExhibitionsAPI(params);
      setExhibition((e) => [...e, ...res.data.data.exhibitions.data]);
      setMeta(res.data.data.exhibitions.meta);
      setIsLoading(false);
      setLoadMoreButton(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    }
  };

  useEffect(() => {
    fetchExhibitions();
  }, []);

  const LoadMore = () => {
    setLoadMoreButton(true);
    let params = {
      perPage: 9,
      page: meta.currentPage + 1,
    };
    fetchExhibitions(params);
  };

  const openFreeExhibitionForGuest = (id) => {
    setExhibitionId(id);
    setShowFreeExhibitionForGuest(true);
    freeExhibitionForGuestRef.current.showModal();
  };

  const closeFreeExhibitionForGuest = () => {
    setExhibitionId(null);
    setShowFreeExhibitionForGuest(false);
    freeExhibitionForGuestRef.current.dismissModal();
  };

  const openPaidExhibitionForGuest = () => {
    setShowPaidExhibitionForGuest(true);
    paidExhibitionForGuestRef.current.showModal();
  };

  const closePaidExhibitionForGuest = () => {
    setShowPaidExhibitionForGuest(false);
    paidExhibitionForGuestRef.current.dismissModal();
  };

  const openImgModal = () => {
    setShowImgModal(true);
    imgModalRef.current.showModal();
  };

  const closeImgModal = () => {
    setShowImgModal(false);
    imgModalRef.current.dismissModal();
  };

  function handlePhoto(photosData) {
    setPhotos(photosData);
  }

  const handleVisitAPI = async (id, exMode) => {
    const visitPayload = {
      name:
        localStorage.getItem("i18nextLng") === "en" ? localStorage.getItem("name_en") : localStorage.getItem("name_ar"),
      email: localStorage.getItem("email"),
      mobile: localStorage.getItem("phone"),
      exhibition_id: id,
      exhibition_type: exMode,
    };

    dispatch(exhibitionVisit(visitPayload));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12" style={{ marginTop: 30 }}>
          {isLoading ? (
            <SkeletonCardOverlay skeletonWidth="100" />
          ) : (
            <>
              <MainBox className="border-8">
                <Title title={t("Exhibition.Exhibition")} />
                <div className="cards-grid">
                  {exhibition && exhibition.length > 0 ? (
                    exhibition?.map((e, index) => (
                      <Card classes={("border-8", "exhibition-card")} key={index}>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <ImageWithLabel
                            imgUrl={e.main_image}
                            label={
                              e.mode === "Paid"
                                ? `${t("Exhibition.entry_cost")} ${e.entrance_cost} ${t("Exhibition.rs")}`
                                : t("Exhibition.free")
                            }
                          />
                        </div>

                        <div className="cards-details">
                          <Logo logoUrl={e.logo} />
                          <div className="sub-details">
                            <Title title={e.name} fontSize={20} />
                            <div>
                              {(e.is_active || e.is_coming) && e.user_status === 1 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.awaiting_approval")}
                                  labelColor="#B5B845"
                                />
                              ) : (e.is_active || e.is_coming) && e.user_status === 2 && e.mode !== "Free" ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.has_been_approved")}
                                  labelColor="#56B1B7"
                                />
                              ) : (e.is_active || e.is_coming) && e.user_status === 3 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.entry_declined")}
                                  labelColor="#DF4B43"
                                />
                              ) : null}
                            </div>
                            {roles?.includes("sponser") &&
                              ((e.is_active || e.is_coming) && e.sponsor_status === 1 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.waiting_for_approval_of_the_reservation")}
                                  labelColor="#C8C8C8"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 2 && e.payment_status === 0 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.reservation_approved")}
                                  labelColor="#B5B845"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 3 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.reservation_declined")}
                                  labelColor="#56B1B7"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 2 && e.payment_status === 1 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.waiting_for_approval_of_the_payment")}
                                  labelColor="#C8C8C8"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 2 && e.payment_status === 2 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.payment_approved")}
                                  labelColor="#AF8BBE"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 2 && e.payment_status === 3 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.payment_declined")}
                                  labelColor="#DF4B43"
                                />
                              ) : (e.is_active || e.is_coming) && e.sponsor_status === 4 ? (
                                <Badge
                                  classes="sm-font-size"
                                  label={t("Exhibition.reservation_canceled_by_admin")}
                                  labelColor="#DF4B43"
                                />
                              ) : null)}
                            {roles?.includes("sponser") &&
                              (e.is_active || e.is_coming) &&
                              (Number(e.user_booths[e?.user_booths.length - 1]?.edit_status) === 5 &&
                              e.sponsor_status === 2 ? (
                                <Badge classes="sm-font-size" label={t("booth.edit_waiting")} labelColor="#B5B845" />
                              ) : Number(e.user_booths[e?.user_booths.length - 1]?.edit_status) === 6 &&
                                e.sponsor_status === 2 ? (
                                <Badge classes="sm-font-size" label={t("booth.edit_approve")} labelColor="#56B1B7" />
                              ) : Number(e.user_booths[e?.user_booths.length - 1]?.edit_status) === 7 &&
                                e.sponsor_status === 2 ? (
                                <Badge classes="sm-font-size" label={t("booth.edit_reject")} labelColor="#DF4B43" />
                              ) : Number(e.user_booths[e?.user_booths.length - 1]?.edit_status) === 8 &&
                                e.sponsor_status === 2 ? (
                                <Badge classes="sm-font-size" label={t("booth.edit_by_admin")} labelColor="#AF8BBE" />
                              ) : null)}

                            {!e.is_active && !e.is_coming && (
                              <Badge classes="sm-font-size" label={t("Exhibition.ended")} labelColor="#DF4B43" />
                            )}

                            <div className="data-rate">
                              <IconWithText
                                text={`${formatDate(new Date(e.start_time))}
                                  ${t("Exhibition.to")} ${formatDate(new Date(e.end_time))} `}
                                icon={calendar}
                                color={"#26b3b9"}
                              />
                              <IconWithText
                                text={`${e.booths_no} ${t("Exhibition.booth")}`}
                                icon={booth}
                                color={"#26b3b9"}
                              />
                              <div className="flip-star">
                                <StarsRating rating={e.rate_avg || 0} cutStyle="star-font-size" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-buttons" style={{ marginTop: "auto" }}>
                          <div className={`sub-buttons`}>
                            {e.is_coming && (
                              <CustomButton
                                value={t("Exhibition.start_soon")}
                                colors={"#26b3b9"}
                                type={"button"}
                                variant={"outlined"}
                                readOnly={true}
                              />
                            )}
                            {e.is_active && (
                              <CustomButton
                                value={t("Exhibition.entry_to_the_exhibition")}
                                colors={"#26b3b9"}
                                classes={"button-font-size"}
                                type={"button"}
                                variant={"outlined"}
                                disable={
                                  e.user_status === 1 || Number(e.visitors_no) <= Number(e.total_visit_requests)
                                }
                                action={() => {
                                  if (!isLogged) {
                                    if (e.mode === "Free") {
                                      openFreeExhibitionForGuest(e.id);
                                    } else {
                                      openPaidExhibitionForGuest();
                                    }
                                  } else {
                                    if (
                                      e.mode === "Free" ||
                                      (roles?.includes("sponser") &&
                                        e.sponsor_status === 2 &&
                                        e.payment_status === 2) ||
                                      e.user_status === 2
                                    ) {
                                      if (e.exhibition_visits === null) {
                                        handleVisitAPI(e.id, e.mode.toUpperCase());
                                      }
                                      localStorage.setItem("exhibition_id", e.id);
                                      history.push(`/show-exhibition/unity-iframe/${e.id}`);
                                    } else {
                                      history.push(`/exhibition-payment/visitor/${e.id}`);
                                    }
                                  }
                                }}
                              />
                            )}
                            {e.photos?.length > 0 && (
                              <CustomButton
                                type={"button"}
                                icon={gallery}
                                classes={"no-padding"}
                                iconClass={"icon-w"}
                                tooltip={t("Exhibition.photo_gallery")}
                                action={() => {
                                  handlePhoto(e.photos);
                                  openImgModal();
                                }}
                              />
                            )}
                          </div>
                          <div className="sub-buttons">
                            {!roles?.includes("sponser") ? null : (
                              <>
                                {(e.is_active || e.is_coming) &&
                                (e.sponsor_status === 0 ||
                                  e.sponsor_status === 4 ||
                                  e.sponsor_status === 5 ||
                                  e.sponsor_status === 6) ? (
                                  <CustomButton
                                    value={t(`Exhibition.book_a_corner${e.booth_mode === "Free" ? "_free" : ""}`)}
                                    colors={"#26b3b9"}
                                    type={"button"}
                                    icon={plus}
                                    iconClass={"mt-2"}
                                    classes={"button-font-size"}
                                    action={() => {
                                      history.push(`/exhibition-booth/${e.id}`);
                                    }}
                                    disable={e.is_full}
                                    tooltip={e.is_full ? t("Exhibition.full") : ""}
                                  />
                                ) : null}
                                {e.sponsor_status !== 0 &&
                                e.sponsor_status !== 4 &&
                                e.sponsor_status !== 5 &&
                                e.sponsor_status !== 6 ? (
                                  <div className="actions-button">
                                    <CustomButton
                                      type={"button"}
                                      icon={greenEye}
                                      iconClass={"icon-w"}
                                      classes={"no-padding"}
                                      action={() => {
                                        history.push(`/booth-details/${e.user_booths[e?.user_booths.length - 1].id}`);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </Card>
                    ))
                  ) : (
                    <div className="no-data">
                      <span>{t("Exhibition.no_data")}</span>
                    </div>
                  )}
                </div>
                {meta.nextPage && (
                  <CustomButton
                    value={t("Exhibition.load_more")}
                    colors={"#26b3b9"}
                    type={"button"}
                    variant={"outlined"}
                    disable={loadMoreButton}
                    loading={loadMoreButton}
                    classes={"justify-center"}
                    action={LoadMore}
                  />
                )}
              </MainBox>
              <BasicModal ref={imgModalRef}>
                {showImgModal && (
                  <MainBox className="border-8 w-60">
                    <div className="popup-header">
                      <Title title={t("Exhibition.popupـimgـlabel")} />
                      <img src={deleteIcon} alt="close" onClick={closeImgModal} className="pointer" />
                    </div>

                    <div className="slider-container ">
                      <MainCarousel imagesList={photos} />
                    </div>
                  </MainBox>
                )}
              </BasicModal>
              <BasicModal ref={freeExhibitionForGuestRef}>
                {showFreeExhibitionForGuest && (
                  <FreeExhibitionForGuestModal
                    closeFreeExhibitionForGuest={closeFreeExhibitionForGuest}
                    exhibitionId={exhibitionId}
                  />
                )}
              </BasicModal>
              <BasicModal ref={paidExhibitionForGuestRef}>
                {showPaidExhibitionForGuest && (
                  <WarningModal
                    img={warning}
                    label={t("Exhibition.popupwarninglabel")}
                    description={t("Exhibition.popupdescription")}
                    dismissText={t("Exhibition.popupdismiss")}
                    dismissColor="#DF4B43"
                    successText={t("Exhibition.popupsuccess")}
                    successColor="#26b3b9"
                    action={() => {
                      closePaidExhibitionForGuest();
                      history.push("/login");
                    }}
                    dismiss={closePaidExhibitionForGuest}
                  />
                )}
              </BasicModal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
