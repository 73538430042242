import { Stack, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { contactUs } from "../../../Services/api/toutorials/contactUs";
import {
  BoxCard,
  InputWithLabel,
  MainBtn,
  TextareaWithLabel,
} from "../../Shared/Components";
import PhoneInput from "../../Shared/Components/PhoneInput/PhoneInput";
import env from "./../../../Services/api/Environment";
import "./contactUs.css";

function ContactUsForm() {
  const { t } = useTranslation();
  const [isVerified, setVerified] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const theme = useTheme();
  const verifyCallback = (response) => {
    if (response) {
      setVerified(!isVerified);
    }
  };

  return (
    <BoxCard
      sx={{
        height: "100%",
      }}
    >
      <Typography
        component={"h1"}
        variant="h2"
        color={"primary.main"}
        fontWeight={600}
        mb={"15px"}
        fontFamily="dinNextRegular"
      >
        {t("contactUs")}
      </Typography>
      <Typography
        component={"h2"}
        variant="subtitle2"
        color={"grey.100"}
        mb={"45px"}
        fontFamily="dinNextRegular"
      >
        {t("contactUsSuggestion")}
      </Typography>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone_number: "",
          subject: "",
          message: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (isVerified) {
            try {
              let response = await contactUs(values);
              if (response.status === 201 && response.data.status) {
                toast.success(
                  <span style={{ fontSize: 14 }}>{t("contactUsSuccess")}</span>
                );
                values.name = "";
                values.email = "";
                values.phone_number = "";
                values.subject = "";
                values.message = "";
                values.code = "";
                setTimeout(() => window.location.reload(), 250);
              } else {
                alert("Failure");
              }
              setSubmitting(false);
            } catch (err) {
              alert(
                err.response.data && err.response.data.msg
                  ? err.response.data.msg
                  : "Failure in service"
              );
            }
          } else {
            toast.error(<span style={{ fontSize: 14 }}>{t("isRobot")}</span>);
          }
        }}
        validateOnChange={hasSubmitted}
        validate={(values) => {
          console.log(values);
          setHasSubmitted(true);
          const errors = {};
          var pattern = /^\d+$/;

          if (!values.name) {
            errors.name = t("crud.errors.required");
          }

          if (!values.email) {
            errors.email = t("crud.errors.required");
          }

          if (!values.phone_number) {
            errors.phone_number = t("crud.errors.required");
          } else if (!pattern.test(values.phone_number)) {
            errors.phone_number = t("crud.errors.fieldNumber");
          }

          if (!values.subject) {
            errors.subject = t("crud.errors.required");
          }

          if (!values.message) {
            errors.message = t("crud.errors.required");
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Stack component={"form"} onSubmit={handleSubmit} spacing={"25px"}>
            <InputWithLabel
              id="name"
              name="name"
              label={"name"}
              placeholder={"name"}
              type="text"
              handleChange={handleChange}
              values={values}
              error={errors.name ? errors.name : null}
            />
            <InputWithLabel
              id="email"
              name="email"
              label={"email"}
              placeholder={"email"}
              type="email"
              handleChange={handleChange}
              values={values}
              error={errors.email ? errors.email : null}
            />
            <Stack
              sx={{
                "& input": {
                  height: "75px !important",
                  borderRadius: "4px",
                  borderWidth: "2px",
                  "&:hover": {
                    borderColor: "#000000",
                  },
                  "&:focus": {
                    borderColor: theme.palette.primary.main,
                  },
                },
                "& .css-gcc56j-control,& .css-wpeh55-control": {
                  height: "75px !important",
                  minHeight: "75px !important",
                  maxHeight: "75px !important",
                },
                "& .css-wpeh55-control": {
                  "&:hover": {
                    height: "75px !important",
                    minHeight: "75px !important",
                    maxHeight: "75px !important",
                  },
                },
              }}
            >
              <PhoneInput
                id="phone_number"
                label={t("Exhibition.popupphone")}
                type="text"
                nameInput="phone_number"
                placeholder={t("Exhibition.popupphone")}
                nameSelect="code"
                selectValue={values.code}
                value={values.phone_number}
                onChange={handleChange}
                min={0}
                error={errors.phone_number ? errors.phone_number : null}
              />
            </Stack>
            <InputWithLabel
              id="subject"
              name="subject"
              label={"messageTitle"}
              placeholder={"messageTitle"}
              type="text"
              handleChange={handleChange}
              values={values}
              error={errors.subject ? errors.subject : null}
            />
            <TextareaWithLabel
              id="message"
              name="message"
              label={"messageSubject"}
              placeholder={"messageSubject"}
              handleChange={handleChange}
              values={values}
              error={errors.message ? errors.message : null}
            />
            <ReCAPTCHA
              sitekey={env.siteKey}
              render="explicit"
              onChange={verifyCallback}
            />
            <Stack alignItems={"end"}>
              <MainBtn
                width={182}
                height={75}
                borderRadius={"6px"}
                type="submit"
                disabled={isSubmitting}
              >
                {t("sendmes")}
              </MainBtn>
            </Stack>
          </Stack>
        )}
      </Formik>
    </BoxCard>
  );
}

export default ContactUsForm;
