import { Box, CircularProgress } from "@material-ui/core";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { getAllPaymentsForProgram } from "../../../../Services/api/CoursesList";
import { sendCouponGift } from "../../../../Services/api/coupon/couponProvider";
import {
  getCourseDetails,
  getProgramDetails,
} from "../../../../Services/api/courses/courseProvider";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import classes from "../../../Admin/Coupon/ManageCoupon/manageCoupon.module.css";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import MultiInput from "../../../Shared/Components/MultiInput/MultiInput";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

const GiftCoupon = ({ type }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [courseDetails, setCourseDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hoursDatePicker, setHoursDatePicker] = useState(null);
  const [minutesDatePicker, setMinutesDatePicker] = useState(null);
  const [hoursDatePickerEnd, setHoursDatePickerEnd] = useState(null);
  const [minutesDatePickerEnd, setMinutesDatePickerEnd] = useState(null);
  const [paymentsForProgram, setPaymentsForProgram] = useState([]);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
    } else if (datePicker === "endDate") {
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    } else {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    }
  };

  const getCourseDetailsAPI = () => {
    const getCourseAPI =
      type === "program" ? getProgramDetails : getCourseDetails;
    getCourseAPI(id)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setCourseDetails(
            type === "program" ? res.data.program : res.data.course
          );
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllPaymentsForProgramHandler = async () => {
    getAllPaymentsForProgram(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPaymentsForProgram(res?.data?.payments || []);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  useEffect(() => {
    currentDate();
    getCourseDetailsAPI();
    if (type === "program") getAllPaymentsForProgramHandler();
  }, []);

  return (
    <div className={classes["create_coupon"]}>
      <div className="container-fluid">
        <div className={classes["create_coupon__container"]}>
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      name: "",
                      emails: [],
                      value: "",
                      mechanism: "2",
                      parent_id: id,
                      include_tax: false,
                      program_payment_id: "",
                      start_date:
                        moment(new Date(courseDetails?.start_date)).toDate() >
                        new Date()
                          ? setHours(
                              setMinutes(
                                moment(
                                  new Date(courseDetails?.start_date)
                                ).toDate(),
                                0
                              ),
                              0
                            )
                          : setHours(
                              setMinutes(new Date(), minutesDatePicker),
                              hoursDatePicker
                            ),
                      end_date: setHours(
                        setMinutes(
                          moment(new Date(courseDetails?.end_date)).toDate(),
                          0
                        ),
                        0
                      ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let dataToBeUploaded = {
                          ...values,
                        };
                        if (type === "course") {
                          delete dataToBeUploaded.program_payment_id;
                        }
                        dataToBeUploaded.include_tax =
                          dataToBeUploaded.include_tax ? 1 : 0;

                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_date))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_date))
                        );

                        const response = await sendCouponGift(dataToBeUploaded);
                        if (response.status === 200 && response.data.status) {
                          history.push(
                            `/training-${
                              type === "course" ? "courses" : "programs"
                            }/${id}/details`
                          );
                          toast.success(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {response.data.msg
                                ? response.data.msg
                                : t("coupon.messages.create_success")}
                            </span>,
                            {
                              onClose: () => {},
                              autoClose: 1000,
                            }
                          );
                        }
                        setSubmitting(false);
                      } catch (err) {
                        toast.error(
                          <>
                            {err?.data?.errors ? (
                              Object.keys(err?.data?.errors)?.map((key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err?.data?.errors[key]}
                                    <br />
                                  </span>
                                );
                              })
                            ) : (
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {err?.data?.msg}
                              </span>
                            )}
                          </>
                        );
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }
                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }
                      if (!values.value) {
                        errors.value = t("crud.errors.required");
                      } else if (values.value > 99 || values.value < 1) {
                        errors.value = t(
                          `coupon.messages.percentage_99_${type}`
                        );
                      }
                      if (!values.program_payment_id && type === "program") {
                        errors.program_payment_id = t("crud.errors.required");
                      }
                      if (values.emails.length === 0) {
                        errors.emails = t("crud.errors.required");
                      }
                      if (values.emails.length > 0) {
                        const emailsErrors = [];
                        values.emails.forEach((email, index) => {
                          if (!email) {
                            emailsErrors[index] = t("crud.errors.required");
                          } else if (
                            !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
                          ) {
                            emailsErrors[index] = t("crud.errors.invalidEmail");
                          }
                        });
                        if (emailsErrors.length > 0) {
                          errors.emails = emailsErrors;
                        }
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setValues,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_coupon__form"]}
                      >
                        <div>
                          <MainBoxHead title={t("gift_coupon")} />
                          {courseDetails.is_scfhsv ? (
                            <div
                              className={
                                classes["create_coupon__form__warning"]
                              }
                            >
                              {t("coupon.messages.scfhsv_warning")}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={`row ${classes["create_coupon__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <Input
                              label={t("coupon.placeholders.coupon_name")}
                              type="text"
                              name="name"
                              placeholder={t("coupon.placeholders.coupon_name")}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                            />
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-9`}
                          >
                            <Input
                              label={t("coupon.placeholders.value_coupon")}
                              type="number"
                              name="value"
                              placeholder={t(
                                "coupon.placeholders.value_coupon"
                              )}
                              onChange={handleChange}
                              error={errors.value}
                              min={1}
                              max={99}
                              value={values.value}
                              note={
                                type === "program"
                                  ? values.program_payment_id
                                    ? `${t("coupon_price")}:${
                                        values.include_tax
                                          ? Number(
                                              (paymentsForProgram.find(
                                                (item) =>
                                                  item.id ==
                                                  values.program_payment_id
                                              ).amount *
                                                0.15 *
                                                (values.value / 100) +
                                                paymentsForProgram.find(
                                                  (item) =>
                                                    item.id ==
                                                    values.program_payment_id
                                                ).amount *
                                                  (values.value / 100)) *
                                                (values.emails.length || 1)
                                            ).toFixed(2)
                                          : Number(
                                              paymentsForProgram.find(
                                                (item) =>
                                                  item.id ==
                                                  values.program_payment_id
                                              ).amount *
                                                (values.value / 100) *
                                                (values.emails.length || 1)
                                            ).toFixed(2)
                                      } ${t("rs")}`
                                    : t("pls_chose_payment")
                                  : `${t("coupon_price")}:${
                                      values.include_tax
                                        ? Number(
                                            (courseDetails?.price *
                                              0.15 *
                                              (values.value / 100) +
                                              courseDetails?.price *
                                                (values.value / 100)) *
                                              (values.emails.length || 1)
                                          ).toFixed(2)
                                        : Number(
                                            courseDetails?.price *
                                              (values.value / 100) *
                                              (values.emails.length || 1)
                                          ).toFixed(2)
                                    } ${t("rs")}`
                              }
                            />
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} ${classes["create_coupon__form__field--checkbox"]} col-12 col-md-3`}
                          >
                            <Checkbox
                              name="include_tax"
                              label={t("financial.label.include_tax")}
                              onChange={(event) => {
                                setFieldValue(
                                  "include_tax",
                                  event.target.checked
                                );
                              }}
                              checked={values.include_tax}
                              error={errors.include_tax}
                            />
                          </div>
                          <div
                            className="col-md-12"
                            style={{ paddingBottom: "38px" }}
                          >
                            <MultiInput
                              label={t("coupon.placeholders.emails")}
                              name="emails"
                              placeholder={t("coupon.placeholders.emails")}
                              itemPlaceholder={t("coupon.placeholders.email")}
                              setFieldValue={setFieldValue}
                              required={true}
                              error={errors.emails}
                              value={values?.emails}
                            />
                          </div>
                          {paymentsForProgram.length > 0 &&
                          type === "program" ? (
                            <div
                              className={`${classes["create_coupon__form__field"]} col-12`}
                            >
                              <label htmlFor="program_payment_id">
                                {t("payment_setting")}
                              </label>
                              <Select
                                name="program_payment_id"
                                id="program_payment_id"
                                options={paymentsForProgram.map(
                                  (option, index) => ({
                                    id: option.id,
                                    indx: index,
                                    amount: option.amount,
                                    start_date: option.payment_date,
                                    end_date: option.end_date,
                                  })
                                )}
                                getOptionLabel={(option) =>
                                  `${t("payment_setting")} ${
                                    option.indx + 1
                                  } - ${option.amount} ${t("rs")} - ${t(
                                    "date"
                                  )}: ${moment(option.start_date).format(
                                    "DD/MM/YYYY"
                                  )} - ${moment(option.end_date).format(
                                    "DD/MM/YYYY"
                                  )}`
                                }
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setValues({
                                    ...values,
                                    program_payment_id: item.id,
                                    start_date: setHours(
                                      setMinutes(new Date(item.start_date), 0),
                                      0
                                    ),
                                    end_date: setHours(
                                      setMinutes(new Date(item.end_date), 0),
                                      0
                                    ),
                                  });
                                }}
                                placeholder={t("payment_setting")}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.program_payment_id
                                  ? errors.program_payment_id
                                  : null}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.start_date_time")}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              note={
                                type === "program"
                                  ? t("coupon.messages.start_date_note")
                                  : ""
                              }
                              onChange={(date) => {
                                setFieldValue("start_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePicker(0);
                                  setMinutesDatePicker(0);
                                } else {
                                  currentDate("startDate");
                                }
                              }}
                              minDate={
                                new Date(
                                  moment(
                                    type === "course"
                                      ? courseDetails?.start_date
                                      : paymentsForProgram.find(
                                          (payment) =>
                                            payment.id ===
                                            values.program_payment_id
                                        )?.payment_date
                                  ) > new Date()
                                    ? moment(
                                        type === "course"
                                          ? courseDetails?.start_date
                                          : paymentsForProgram.find(
                                              (payment) =>
                                                payment.id ===
                                                values.program_payment_id
                                            )?.payment_date
                                      ).format("YYYY-MM-DD")
                                    : moment(new Date()).format("YYYY-MM-DD")
                                )
                              }
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePicker),
                                hoursDatePicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={
                                new Date(
                                  moment(
                                    type === "course"
                                      ? courseDetails?.end_date
                                      : paymentsForProgram.find(
                                          (payment) =>
                                            payment.id ===
                                            values.program_payment_id
                                        )?.end_date
                                  ).format("YYYY-MM-DD")
                                )
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.start_date ? errors.start_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.end_date_time")}
                              name="end_date"
                              note={
                                type === "program"
                                  ? t("coupon.messages.end_date_note")
                                  : ""
                              }
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePickerEnd(0);
                                  setMinutesDatePickerEnd(0);
                                } else {
                                  currentDate("endDate");
                                }
                              }}
                              minDate={
                                new Date(
                                  moment(
                                    type === "course"
                                      ? courseDetails?.start_date
                                      : paymentsForProgram.find(
                                          (payment) =>
                                            payment.id ===
                                            values.program_payment_id
                                        )?.payment_date
                                  ) > new Date()
                                    ? moment(
                                        type === "course"
                                          ? courseDetails?.start_date
                                          : paymentsForProgram.find(
                                              (payment) =>
                                                payment.id ===
                                                values.program_payment_id
                                            )?.payment_date
                                      ).format("YYYY-MM-DD")
                                    : moment(new Date()).format("YYYY-MM-DD")
                                )
                              }
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePickerEnd),
                                hoursDatePickerEnd
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={
                                new Date(
                                  moment(
                                    type === "course"
                                      ? courseDetails?.end_date
                                      : paymentsForProgram.find(
                                          (payment) =>
                                            payment.id ===
                                            values.program_payment_id
                                        )?.end_date
                                  ).format("YYYY-MM-DD")
                                )
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.end_date ? errors.end_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field-actions"]} col-12`}
                          >
                            <div className="tw-font-bold">
                              {type === "program"
                                ? values.program_payment_id
                                  ? `${t("coupon_price")}:${
                                      values.include_tax
                                        ? Number(
                                            (paymentsForProgram.find(
                                              (item) =>
                                                item.id ==
                                                values.program_payment_id
                                            ).amount *
                                              0.15 *
                                              (values.value / 100) +
                                              paymentsForProgram.find(
                                                (item) =>
                                                  item.id ==
                                                  values.program_payment_id
                                              ).amount *
                                                (values.value / 100)) *
                                              (values.emails.length || 1)
                                          ).toFixed(2)
                                        : Number(
                                            paymentsForProgram.find(
                                              (item) =>
                                                item.id ==
                                                values.program_payment_id
                                            ).amount *
                                              (values.value / 100) *
                                              (values.emails.length || 1)
                                          ).toFixed(2)
                                    } ${t("rs")}`
                                  : t("pls_chose_payment")
                                : `${t("coupon_price")}:${
                                    values.include_tax
                                      ? Number(
                                          (courseDetails?.price *
                                            0.15 *
                                            (values.value / 100) +
                                            courseDetails?.price *
                                              (values.value / 100)) *
                                            (values.emails.length || 1)
                                        ).toFixed(2)
                                      : Number(
                                          courseDetails?.price *
                                            (values.value / 100) *
                                            (values.emails.length || 1)
                                        ).toFixed(2)
                                  } ${t("rs")}`}
                            </div>
                            <button
                              className={
                                classes[
                                  "create_coupon__form__field-actions-submit"
                                ]
                              }
                              type="submit"
                              disabled={isSubmitting}
                              onClick={handleSubmit}
                            >
                              {isSubmitting ? (
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress color="white" size={30} />
                                </Box>
                              ) : (
                                t("Exhibition.save")
                              )}
                            </button>
                          </div>
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GiftCoupon;
