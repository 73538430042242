import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchCoursesList } from "../../../Redux/Actions/courses/coursesList.action";
import { fetchDepartmentList } from "../../../Redux/Actions/departments/departmentList.action";
import { exportCoursesList, exportOneCourse, exportOneProgram } from "../../../Services/api/CoursesList";
import { getIntroVideo, getProgramDetails, reorderProgramCourses } from "../../../Services/api/courses/courseProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import IntroVideoModal from "../../Shared/Components/IntroViedoModal/IntroVideoModal";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import CloneCourseModal from "./CloneCourseModal";
import CoursesListFilter from "./CoursesListFilter";
import CoursesListTable from "./CoursesListTable";
import { filterActions } from "./CoursesListTableActions";
import ReorderProgramCoursesModal from "./ReorderProgramCoursesModal";
import classes from "./courses.module.css";

const CoursesList = ({
  role,
  isProgram,
  programCourses,
  inProgram,
  notInProgram,
  status,
  isHistoryCourses,
  isHistoryPrograms,
  notClosed,
  backPath,
  previewRoute,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const cloneCourseModalRef = useRef();
  const reorderProgramCoursesModalRef = useRef();

  const { coursesListMeta, allCourses } = useSelector((state) => state.coursesListReducer);
  const { departmentList } = useSelector((state) => state.departmentListReducer);

  const [showFilter, setShowFilter] = useState(false);
  const [showAddIntroVideoModal, setAddIntroVideoModal] = useState(false);
  const [showReorderProgramCoursesModal, setShowReorderProgramCoursesModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isReorderProgramCoursesLoading, setIsReorderProgramCoursesLoading] = useState(false);
  const [showCloneCourseModal, setShowCloneCourseModal] = useState(false);
  const [currentCourseFilter, setCurrentCourseFilter] = useState({});
  const [courseDetails, setCourseDetails] = useState(null);
  const [introVideo, setIntroVideo] = useState(null);

  const handleShowIntroVideoModalStat = (value) => {
    setAddIntroVideoModal(value);
  };

  const fetchCourseIntroVideo = async () => {
    await getIntroVideo()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setIntroVideo(res.data.data.file);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (programCourses && role !== "trainer") {
      getProgramDetails(id)
        .then((res) => {
          setCourseDetails(res.data.program);
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        });
    }

    if (role === "admin" && !isProgram && !programCourses) {
      fetchCourseIntroVideo();
    }
  }, []);

  const [filterData, setFilterData] = useState({
    name: "",
    description: "",
    owner: "",
    mode: "",
    cost: "",
    specialty: "",
    department: "",
    start_date: "",
    end_date: "",
    status: "",
    is_visible: "",
    code: "",
    run_serial_number: "",
    language: "",
    level: "",
    trainer_ids: "",
    manager_id: "",
    created_by: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(`sidebar.label.${role === "admin" ? "content_development" : "private_profile"}`),
      pagePath: role === "admin" ? "#" : "/private-profile",
    },
    {
      id: `${isProgram || programCourses ? "programs" : "courses"}`,
      page: t(`sidebar.label.${isProgram || programCourses ? "program" : "courses"}`),
      [programCourses ? "pagePath" : "active"]: programCourses
        ? role === "trainer"
          ? "#"
          : `${
              role === "manager" || role === "partner"
                ? backPath
                  ? `/${role}/programs-history`
                  : `/${role}/programs`
                : "/admin/program/list"
            }`
        : true,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            active: true,
          },
        ]
      : []),
  ];

  const openCloneCourseModalHandler = () => {
    setShowCloneCourseModal(true);
    cloneCourseModalRef.current.showModal();
  };

  const closeCloneCourseModalHandler = () => {
    setShowCloneCourseModal(false);
    cloneCourseModalRef.current.dismissModal();
  };

  const openReorderProgramCoursesModalHandler = () => {
    setShowReorderProgramCoursesModal(true);
    reorderProgramCoursesModalRef.current.showModal();
  };

  const closeReorderProgramCoursesModalHandler = () => {
    setShowReorderProgramCoursesModal(false);
    reorderProgramCoursesModalRef.current.dismissModal();
  };

  const addIntroVideoHandler = () => {
    setAddIntroVideoModal((prev) => !prev);
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      description: "",
      owner: "",
      mode: "",
      cost: "",
      specialty: "",
      department: "",
      start_date: "",
      end_date: "",
      status: "",
      is_visible: "",
      code: "",
      run_serial_number: "",
      language: "",
      level: "",
      trainer_ids: "",
      manager_id: "",
      created_by: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {
      ...(!programCourses && role === "manager" ? (!notClosed ? {} : { not_closed: 1 }) : {}),
    };
    filterData.name && (filter.name = filterData.name);
    filterData.description && (filter.description = filterData.description);
    filterData.owner && (filter.owner = filterData.owner);
    filterData.trainer_ids && (filter.trainer_ids = [filterData.trainer_ids] || []);
    filterData.manager_id && (filter.manager_id = filterData.manager_id);
    filterData.created_by && (filter.created_by = filterData.created_by);
    filterData.mode && (filter.mode = filterData.mode);
    filterData.cost && (filter.cost = filterData.cost);
    filterData.specialty && (filter.category_id = filterData.specialty);
    filterData.department && (filter.section_id = filterData.department);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.status && (filter.status = filterData.status);
    filterData.is_visible && (filter.is_visible = filterData.is_visible);
    filterData.code && (filter.code = filterData.code);
    filterData.run_serial_number && (filter.run_serial_number = filterData.run_serial_number);
    filterData.language && (filter.language = filterData.language);
    filterData.level && (filter.level = filterData.level);

    status && (filter.status = status);
    notClosed && (filter.not_closed = 1);
    isProgram && (filter.is_program = 1);
    inProgram && (filter.not_in_program = 0);
    notInProgram && (filter.not_in_program = 1);
    notInProgram && (filter.is_program = 0); //&& role !== "trainer"
    return filter;
  };

  const getCoursesListHandler = () => {
    const filter = getFilterData();

    dispatch(
      fetchCoursesList({
        page: currentPage,
        perPage: 10,
        programId: programCourses ? id : null,
        filter,
        role,
      })
    );
    setCurrentCourseFilter(filter);
  };

  const getDepartmentsListHandler = async () => {
    dispatch(
      fetchDepartmentList({
        filter: {},
      })
    );
  };

  const reorderProgramCoursesHandler = async (payload) => {
    setIsReorderProgramCoursesLoading(true);
    try {
      const res = await reorderProgramCourses(payload);
      toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
      closeReorderProgramCoursesModalHandler();
      resetFilterHandler();
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{e.response.data.message}</span>);
    } finally {
      setIsReorderProgramCoursesLoading(false);
    }
  };

  useEffect(() => {
    getCoursesListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }

    if (!departmentList?.length) {
      getDepartmentsListHandler();
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      programCourses ? (currentCourseFilter.program_id = id) : (currentCourseFilter.program_id = null);
      await exportCoursesList(currentCourseFilter, type, role, isProgram);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };
  const exportOneProgramHandler = async (type, id) => {
    setIsExportLoading(true);
    try {
      await exportOneProgram(type, id, role);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };
  const exportOneCourseHandler = async (type, id) => {
    setIsExportLoading(true);
    try {
      await exportOneCourse(type, id, role);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  return (
    <div className={classes["courses-list"]}>
      {(isExportLoading || isReorderProgramCoursesLoading) && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["courses-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={isProgram ? t("program") : t("sidebar.label.courses")}
                  actions={filterActions({
                    [role !== "partner" &&
                    role !== "trainer" &&
                    programCourses &&
                    courseDetails?.status !== "Published" &&
                    courseDetails?.payments?.length === 0 &&
                    status !== "Closed"
                      ? "reorderProgramCourses"
                      : ""]: openReorderProgramCoursesModalHandler,
                    [isProgram || role !== "admin" ? "" : "addIntroVideo"]: addIntroVideoHandler,
                    [role !== "partner" &&
                    role !== "trainer" &&
                    !isProgram &&
                    (courseDetails?.status === "Draft" || !programCourses) &&
                    status !== "Closed"
                      ? "cloneCourse"
                      : ""]: openCloneCourseModalHandler,
                    [role !== "partner" && role !== "trainer" && !isProgram && courseDetails?.status === "Published"
                      ? "cloneCourseNotActive"
                      : ""]: () => {},
                    showFilter: showFilterHandler,
                    [role !== "partner" &&
                    role !== "trainer" &&
                    (courseDetails?.status === "Draft" || !programCourses) &&
                    status !== "Closed"
                      ? "addAction"
                      : ""]: () => {
                      history.push(
                        `/${role}/${
                          programCourses ? `program/${id}/course/add` : isProgram ? "program/add" : "course/add"
                        }`
                      );
                    },
                    [role !== "partner" && role !== "trainer" && courseDetails?.status === "Published"
                      ? "addActionNotActive"
                      : ""]: () => {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("course_is_published")}</span>);
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <CoursesListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getCoursesListHandler}
                    resetFilterHandler={resetFilterHandler}
                    isProgram={isProgram}
                    role={role}
                    status={status}
                    programCourses={programCourses}
                    inProgram={inProgram}
                  />
                )}

                <CoursesListTable
                  isAdmin={role === "admin"}
                  isProgram={isProgram}
                  isHistoryCourses={isHistoryCourses}
                  isHistoryPrograms={isHistoryPrograms}
                  programCourses={programCourses}
                  programId={id}
                  role={role}
                  previewRoute={previewRoute}
                  backPath={backPath}
                  exportOneProgramHandler={exportOneProgramHandler}
                  exportOneCourseHandler={exportOneCourseHandler}
                  inProgram={inProgram}
                />
                <Pagination
                  count={coursesListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
            {showAddIntroVideoModal && (
              <IntroVideoModal
                openModal={showAddIntroVideoModal}
                setOpenModal={handleShowIntroVideoModalStat}
                modalTitle={t("add_intro_video")}
                loadingVideo={false}
                video={introVideo}
                setVideo={setIntroVideo}
              />
            )}
          </div>
        </div>
      </div>
      <BasicModal ref={cloneCourseModalRef}>
        {showCloneCourseModal && (
          <CloneCourseModal
            programId={programCourses ? id : null}
            closeModal={closeCloneCourseModalHandler}
            resetFilter={resetFilterHandler}
            programStartDate={courseDetails?.start_date}
            programEndDate={courseDetails?.end_date}
          />
        )}
      </BasicModal>
      <BasicModal ref={reorderProgramCoursesModalRef}>
        {showReorderProgramCoursesModal && (
          <ReorderProgramCoursesModal
            courses={courseDetails?.courses}
            closeModal={closeReorderProgramCoursesModalHandler}
            onConfirm={reorderProgramCoursesHandler}
            programId={id}
          />
        )}
      </BasicModal>
    </div>
  );
};

export default CoursesList;
