import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSSR, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  addRefunds,
  getReset,
} from "../../../../../Redux/Actions/refundSetting/refundSetting.action";
import { toServerTime } from "../../../../../Services/Timezone";
import {
  getAllCourses,
  getAllPaymentsForProgram,
} from "../../../../../Services/api/CoursesList";
import { getExhibitionList } from "../../../../../Services/api/Exhibition/ExhibitionProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../../utils/formatDate";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import Checkbox from "../../../../Shared/Components/Checkbox/Checkbox";
import DataTable from "../../../../Shared/Components/DataTable/DataTable";
import NewDataTable from "../../../../Shared/Components/DataTable/NewDataTable";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import AddAction from "./AddAction";
import classes from "./add.module.css";

const Add = ({ type }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { addRefundStatusResponse } = useSelector(
    (state) => state.refundsListReducer
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentsForProgram, setPaymentsForProgram] = useState([]);

  const [hoursDatePicker, setHoursDatePicker] = useState(null);
  const [minutesDatePicker, setMinutesDatePicker] = useState(null);
  const [hoursDatePickerEnd, setHoursDatePickerEnd] = useState(null);
  const [minutesDatePickerEnd, setMinutesDatePickerEnd] = useState(null);

  const [fetching, setFetching] = useState(true);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [boothExhibition, setBoothExhibition] = useState([]);
  const [visitorExhibition, setVisitorExhibition] = useState([]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `refund`,
      page: t(
        `financial.refund.${
          type === "exhibition-visitors"
            ? "exhibition_visitors_refunds"
            : type === "exhibition-booths"
            ? "exhibition_booth_refunds"
            : "refunds"
        }`
      ),
      pagePath: `/${
        localStorage.getItem("type") === "accountant" ? "accountant" : "admin"
      }/refund${type !== "trainee" ? `/${type}` : ""}`,
    },
    {
      id: "refund-setting",
      page: t("financial.label.refundSetting"),
      pagePath: `${
        localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"
      }/refund-setting/list${type !== "trainee" ? `/${type}` : ""}`,
    },
    {
      id: "refund-add",
      page: t("create_refund"),
      active: true,
    },
  ];
  const mechanismOptions = [
    // {
    //   value: 1,
    //   label: t("coupon.label.co_value"),
    // },
    {
      value: 2,
      label: t("coupon.label.per_value"),
    },
  ];
  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
    },
  ];

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    }
  };

  const getAllPaymentsForProgramHandler = async (id) => {
    getAllPaymentsForProgram(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPaymentsForProgram(res?.data?.payments || []);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  const fetchData = async () => {
    try {
      if (type === "trainee") {
        getAllCourses({
          paginated: 0,
          not_in_program: 1,
          is_program: 0,
          mode: "Paid",
          perPage: 1000,
        }).then((res) => {
          if (res.status === 200 && res.data.status) {
            setCourses(res.data.data.courses.data);
          }
        });
        getAllCourses({
          paginated: 0,
          is_program: 1,
          mode: "Paid",
          perPage: 1000,
        }).then((res) => {
          if (res.status === 200 && res.data.status) {
            setPrograms(res.data.data.courses.data);
          }
        });
      }
      if (type === "exhibition-visitors") {
        getExhibitionList({ perPage: 1000, mode: "Paid" }).then((res) => {
          if (res.status === 200) {
            setVisitorExhibition(res.data.data.exhibitions.data);
          }
        });
      }
      if (type === "exhibition-booths") {
        getExhibitionList({ perPage: 1000, booth_mode: "Paid" }).then((res) => {
          if (res.status === 200) {
            setBoothExhibition(res.data.data.exhibitions.data);
          }
        });
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    currentDate();
    fetchData();
    setIsLoading(false);
  }, []);

  const fields = (type) => {
    const createField = (
      id,
      name,
      valueFormatter = (value) => value || "-"
    ) => ({
      id,
      name: t(`general.${name}`),
      column: ({ rowData }) => <>{valueFormatter(rowData[id])}</>,
    });
    return [
      createField("id", "id"),
      createField("name", "name"),
      ...(type === "courses"
        ? [
            createField("code", "code"),
            createField("run_serial_number", "serial_number"),
            createField("start_date", "start_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
          ]
        : []),
      ...(type === "exhibition"
        ? [
            createField("start_time", "start_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
            createField("end_time", "end_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
          ]
        : []),
    ];
  };

  const parentTypeMapping = {
    1: {
      label: t("coupon.label.select_course"),
      data: courses,
      fields: fields("courses"),
      searchableFields: ["id", "name", "code", "run_serial_number"],
    },
    2: {
      label: t("coupon.label.select_program"),
      data: programs,
      fields: fields("courses"),
      searchableFields: ["id", "name", "code", "run_serial_number"],
    },
    3: {
      label: t("crud.placeholders.select.exhibition"),
      data: boothExhibition,
      fields: fields("exhibition"),
      searchableFields: ["id", "name"],
    },
    4: {
      label: t("crud.placeholders.select.exhibition"),
      data: visitorExhibition,
      fields: fields("exhibition"),
      searchableFields: ["id", "name"],
    },
  };

  useEffect(() => {
    if (
      (addRefundStatusResponse.status === 201 ||
        addRefundStatusResponse.status === 200) &&
      addRefundStatusResponse.data.status
    ) {
      setIsLoading(true);
      history.push(
        `${
          localStorage.getItem("type") == "accountant"
            ? "/accountant"
            : "/admin"
        }/refund-setting/list${type !== "trainee" ? `/${type}` : ""}`
      );

      setIsLoading(false);
      dispatch(getReset());
    }
  }, [addRefundStatusResponse]);

  return (
    <div className={classes["create_coupon"]}>
      {isSubmitting && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["create_coupon__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      title: "",
                      notes: "",
                      amount: "",
                      mechanism: "0",
                      include_tax: false,
                      parentType:
                        type === "exhibition-booths"
                          ? 3
                          : type === "exhibition-visitors"
                          ? 4
                          : 1,
                      parent_id: "",
                      refundable_id: "",
                      refundable_type: "",
                      start_date: setHours(
                        setMinutes(new Date(), minutesDatePicker),
                        hoursDatePicker
                      ),
                      end_date: setHours(
                        setMinutes(new Date(), minutesDatePicker),
                        hoursDatePicker
                      ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setIsSubmitting(true);
                      setSubmitting(true);
                      try {
                        let dataToBeUploaded = {
                          ...values,
                        };
                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_date))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_date))
                        );

                        if (dataToBeUploaded.mechanism == 1) {
                          delete dataToBeUploaded.include_tax;
                        } else {
                          dataToBeUploaded.include_tax =
                            dataToBeUploaded.include_tax ? 1 : 0;
                        }

                        await dispatch(addRefunds(dataToBeUploaded));
                      } catch (err) {
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      } finally {
                        setIsSubmitting(false);
                        setSubmitting(false);
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};
                      if (!values.title || !values.title.trim()) {
                        errors.title = t("crud.errors.required");
                      }
                      if (!values.notes) {
                        errors.notes = t("crud.errors.required");
                      }

                      if (!values.amount) {
                        errors.amount = t("crud.errors.required");
                      }

                      if (!values.parentType) {
                        errors.parentType = t("crud.errors.required");
                      }

                      if (!values.parent_id && type === "trainee") {
                        errors.parent_id = t("crud.errors.required");
                      }
                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }

                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }
                      if (
                        values.parentType === 2 &&
                        paymentsForProgram.length > 1 &&
                        !values.refundable_id
                      ) {
                        errors.refundable_id = t("crud.errors.required");
                      }

                      if (
                        values.mechanism == 2 &&
                        (values.amount < 0 || values.amount > 100)
                      ) {
                        errors.amount = t("crud.errors.percentage");
                      }

                      // if (!values.grace_period) {
                      //   errors.grace_period = t("crud.errors.required");
                      // }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_coupon__form"]}
                      >
                        <MainBoxHead title={t("create_refund")} />
                        <div
                          className={`row ${classes["create_coupon__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <Input
                              label={t("financial.refund.title")}
                              type="text"
                              name="title"
                              placeholder={t("financial.refund.title")}
                              onChange={handleChange}
                              error={errors.title}
                              value={values.title}
                            />
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <Input
                              label={t("note_for_user")}
                              type="text"
                              name="notes"
                              placeholder={t("note_for_user")}
                              onChange={handleChange}
                              error={errors.notes}
                              value={values.notes}
                            />
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="mechanism">
                              {t("refund_mechanisem")}
                            </label>
                            <Select
                              name="mechanism"
                              id="mechanism"
                              options={mechanismOptions}
                              value={mechanismOptions.find(
                                (option) => option.value == values.mechanism
                              )}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("mechanism", item.value);
                              }}
                              placeholder={t("financial.label.discount_type")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.mechanism ? errors.mechanism : null}
                            </p>
                          </div>
                          <div
                            className={`${
                              classes["create_coupon__form__field"]
                            } col-12 col-md-${values.mechanism == 2 ? 3 : 6}`}
                          >
                            <Input
                              label={`${
                                values.mechanism == 1
                                  ? t("refund_value")
                                  : t("financial.label.percentage")
                              }`}
                              type="number"
                              name="amount"
                              placeholder={`${
                                values.mechanism == 1
                                  ? t("refund_value")
                                  : t("financial.label.percentage")
                              }`}
                              onChange={handleChange}
                              error={errors.amount}
                              min={0.01}
                              max={values.mechanism == 1 ? 100000000 : 100}
                              value={values.amount}
                            />
                          </div>
                          {values.mechanism == 2 ? (
                            <div
                              className={`${classes["create_coupon__form__field"]} ${classes["create_coupon__form__field--checkbox"]} col-12 col-md-3`}
                            >
                              <Checkbox
                                name="include_tax"
                                label={t("financial.label.include_tax")}
                                onChange={(event) => {
                                  setFieldValue(
                                    "include_tax",
                                    event.target.checked
                                  );
                                }}
                                checked={values.include_tax}
                                error={errors.include_tax}
                              />
                            </div>
                          ) : null}
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.start_date_time")}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              onChange={(date) => {
                                setFieldValue("start_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePicker(0);
                                  setMinutesDatePicker(0);
                                } else {
                                  currentDate("startDate");
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePicker),
                                hoursDatePicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.start_date ? errors.start_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.end_date_time")}
                              name="end_date"
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePickerEnd(0);
                                  setMinutesDatePickerEnd(0);
                                } else {
                                  currentDate("endDate");
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePicker),
                                hoursDatePicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.end_date ? errors.end_date : null}
                            </p>
                          </div>
                          {type === "trainee" ? (
                            <div
                              className={`${classes["create_coupon__form__field"]} col-12`}
                            >
                              <label htmlFor="course_program">
                                {t("coupon.label.select_course_or_program")}
                              </label>
                              <Select
                                name="course_program"
                                id="course_program"
                                options={parentTypeOptions}
                                value={parentTypeOptions.find(
                                  (option) => option.id == values.parentType
                                )}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("parentType", item.id);
                                  setFieldValue("parent_id", "");
                                }}
                                placeholder={t(
                                  "coupon.label.select_course_or_program"
                                )}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.parentType ? errors.parentType : null}
                              </p>
                            </div>
                          ) : null}
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <NewDataTable
                              label={parentTypeMapping[values.parentType].label}
                              selectedItems={[values.parent_id]}
                              isLoading={fetching}
                              data={parentTypeMapping[values.parentType].data}
                              fields={
                                parentTypeMapping[values.parentType].fields
                              }
                              isMultiple={false}
                              searchableFields={
                                parentTypeMapping[values.parentType]
                                  .searchableFields
                              }
                              selectedHandler={(item) => {
                                setFieldValue("parent_id", item?.id);
                                if (values.parentType == 2) {
                                  getAllPaymentsForProgramHandler(item?.id);
                                } else {
                                  setFieldValue("refundable_id", item?.id);
                                  setFieldValue(
                                    "refundable_type",
                                    values.parentType == 1
                                      ? "course"
                                      : values.parentType == 3
                                      ? "exhibition_booth"
                                      : "exhibition_visitor"
                                  );
                                }
                              }}
                              error={errors.parent_id}
                              required
                            />
                          </div>
                          {paymentsForProgram.length > 0 &&
                          values.parentType == 2 &&
                          values.parent_id ? (
                            <div
                              className={`${classes["create_coupon__form__field"]} col-12`}
                            >
                              <label htmlFor="refundable_id">
                                {t("payment_setting")}
                              </label>
                              <Select
                                name="refundable_id"
                                id="refundable_id"
                                options={paymentsForProgram.map(
                                  (option, index) => ({
                                    id: option.id,
                                    indx: index,
                                    amount: option.amount,
                                    start_date: option.payment_date,
                                    end_date: option.end_date,
                                  })
                                )}
                                // value={paymentsForProgram.find(
                                //   (option) => option.id
                                // )}
                                getOptionLabel={(option) =>
                                  `${t("payment_setting")} ${
                                    option.indx + 1
                                  } - ${option.amount} ${t("rs")} - ${t(
                                    "date"
                                  )}: ${moment(option.start_date).format(
                                    "DD/MM/YYYY"
                                  )} - ${moment(option.end_date).format(
                                    "DD/MM/YYYY"
                                  )}`
                                }
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("refundable_id", item.id);
                                  setFieldValue(
                                    "refundable_type",
                                    "program_payment"
                                  );
                                }}
                                placeholder={t("payment_setting")}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.refundable_id
                                  ? errors.refundable_id
                                  : null}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}

                          <AddAction
                            submitAction={handleSubmit}
                            isSubmitting={isSubmitting}
                          />
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Add;
