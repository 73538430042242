import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { walletConstants } from "../../Constants/wallet/wallet.constants";

export const fetchUserWalletById = ({ id, page, perPage, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: walletConstants.START_LOADING,
    });

    await connector
      .get(
        URLBuilder(env.user_wallet_id, {
          id,
        }),
        {
          params: {
            page,
            perPage,
            ...filter,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: walletConstants.GET_USER_WALLET_BY_ID,
            payload: {
              dashboard: response.data.data.dashboard,
              meta: response.data.data.meta,
              transactions: response.data.data.transactions,
              user: response.data.data.user,
              filter: response.data.data.filter,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: walletConstants.STOP_LOADING,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: walletConstants.STOP_LOADING,
          payload: err,
        });
      });
  };
};

export const fetchUserWallet = ({ page, perPage, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: walletConstants.START_LOADING,
    });
    await connector
      .get(env.users_wallet, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: walletConstants.GET_USER_WALLET,
            payload: {
              dashboard: response.data.data.dashboard,
              meta: response.data.data.meta,
              transactions: response.data.data.transactions,
              user: response.data.data.user,
              filter: response.data.data.filter,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: walletConstants.STOP_LOADING,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: walletConstants.STOP_LOADING,
          payload: err,
        });
      });
  };
};

export const payWithBankTransfer = (data) => {
  return async (dispatch) => {
    dispatch({
      type: walletConstants.START_LOADING,
    });

    await connector
      .post(env.wallet_credit, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: walletConstants.POST_PAY,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: walletConstants.STOP_LOADING,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg
              ? err?.response?.data?.msg
              : err?.data?.msg
              ? err?.data?.msg
              : "Failed"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

// updateIban

export const updateIban = (data) => {
  return async (dispatch) => {
    dispatch({
      type: walletConstants.START_LOADING,
    });

    await connector
      .post(env.userIban, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: walletConstants.POST_IBAN,
          payload: data,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: walletConstants.STOP_LOADING,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg
              ? err?.response?.data?.msg
              : err?.data?.msg
              ? err?.data?.msg
              : "Failed"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

export const saveWalletTransaction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: walletConstants.START_LOADING,
    });

    await connector
      .post(env.wallet_transaction, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: walletConstants.POST_TRANSACTION,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: walletConstants.STOP_LOADING,
          payload: err,
        });
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err?.response?.data?.msg
                ? err?.response?.data?.msg
                : err?.data?.msg
                ? err?.data?.msg
                : "Failed"}
            </span>
            <ul>
              {Object.keys(err.response.data.errors).map((key) => {
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>
                      {err.response.data.errors[key]}
                    </span>
                  </li>
                );
              })}
            </ul>
          </>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};
